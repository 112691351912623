export default {
    props: {
        // TODO: добавил возможность прокидывать выделенные строки через пропс
        rowsSelected: {
            type: Array,
            default: () => ([])
        },
        // TODO: частично выделенные строки
        rowsSelectedPart: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            checkAll: false,
            selectedCheckboxes: []
        }
    },
    mounted() {
        /** TODO: при монтировании устанавливаем выделенные строки */
        this.selectedCheckboxes = Object.assign([], this.rowsSelected);
    },
    methods: {
        /**
         * Выделяет/снимаетВыделение со всех элементов 
         */
        onCheckAll() {
            if (this.checkAll) {
                this.rows.forEach(row => {
                    if (!this.selectedCheckboxes.includes(row[this.primaryKey])) {
                        this.selectedCheckboxes.push(row[this.primaryKey])
                    }
                });
            } else {
                this.selectedCheckboxes = []
            }
            // TODO: при массовом выделении вызываю событие выделения строки с флагом isCheckAll = true
            // this.onCheckRow({row: null, isCheckAll: true});
            // TODO: при массовом выделении делаю эмит соответствующего события
            this.$emit('onCheckRowsAll', this.selectedCheckboxes.length !== 0);
        },
        /**
         * Событие выделения строки
         * @param {Object} checkData - данные выделения
         */
        onCheckRow(checkData = {}) {
            const {row = null} = checkData;
            this.$nextTick(() => {
                const rowIsSelected = this.selectedCheckboxes.includes(row[this.primaryKey]);
                this.$emit('onCheckRow', {rowsIdsSelected: Object.assign([], this.selectedCheckboxes), row, rowIsSelected});
            });
        },
        /**
         * Условие для флага массового выбора
         */
        isCheckAll() {
            const lengthSelected = this.selectedCheckboxes.length;
            const lengthRows = this.rows.length;
            const rowsPrimary = this.rows.map(row => {
                const rowPrimary = row[this.primaryKey] || '';
                return String(rowPrimary);
            });
            const isSome = rowsPrimary.some((rowPrimary) => {
                return this.selectedCheckboxes.includes(rowPrimary);
            });
            if ((lengthSelected === lengthRows && lengthSelected > 0) && lengthSelected !== 0 && lengthRows !== 0 && isSome) {
                this.checkAll = true;
            } else {
                this.checkAll = false;
            }
        },
        /**
         * Является ли строка частично выделенной
         * @param {Object} row - строка
         * @returns {Boolean}
         */
        isRowSelectedPart(row = {}) {
            return this.rowsSelectedPart.includes((row[this.primaryKey]))
        },
        /**
         * Получить стили для чекбокса
         * @param {Object} row - строка
         * @returns {Object}
         */
        getStyleCheckbox(row = {}) {
            const style = this.isRowSelectedPart(row) ? {'background-image': `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='5.995' cy='5.995' r='5.995' fill='%23fff'/%3e%3c/svg%3e"`} : {};
            return style;
        }
    },
    watch: {
        /**
         * Следим за пропсом выделенных строк
         * TODO: !!!!ВНИМАНИЕ!!!! тут обновляется свойство selectedCheckboxes. 
         * Внимательнее с прослушиванием в родителе $emit('check')
         * если в обработчики этого эмита изменять пропс rowsSelected - произойдет зацикливание
         * Если юзаешь rowsSelected - слушай $emit('onCheckRow')!!!!!!!
         */
        rowsSelected() {
            this.selectedCheckboxes = Object.assign([], this.rowsSelected);
        },
        /**
         * если выделены все чекбоксы, то будет выделен главный чекбокс
         * Эмитит массив id выбранных строк
         */
        selectedCheckboxes() {
            //TODO: Вынес тело в функцию isCheckAll
            this.isCheckAll();
            // if (this.selectedCheckboxes.length === this.rows.length) {
            //     this.checkAll = true
            // } else {
            //     this.checkAll = false
            // }

            this.$emit('check', this.selectedCheckboxes)
        },
        /**
         * Следим за обновлением строк и проверяем условие для флага массового выделения
         */
        rows() {
            this.isCheckAll();
        }
    }
}