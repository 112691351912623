import toRuble from "@/filters/toRuble";
export default {
    /**
     * @property {Array} summaryData - сводные данные
     */
    data: () => ({
        summaryData: [],
    }),
    methods: {
        AdapterSummaryData(summaryData, isModified = false) {
            const totalValue = summaryData?.total?.price
            // const sideCount = summaryData?.total?.side_count
            const totalOfferValue = summaryData?.total?.offer_price
            let sides = summaryData?.sides || []
            sides = sides.map(item => ({title: item.name, value: `${item.side_count} шт.`}))

            if (sides.length > 0 && !isModified) {
                return [
                    // { title: "Сторон", value: `${sideCount} шт.` },
                    // { title: "Прайс без НДС", value: toRuble(totalValue) },
                    { title: "Прайс", value: toRuble(totalValue) },
                    // { title: "Цена агентства без НДС", value: toRuble(totalOfferValue)},
                    { title: "Цена агентства", value: toRuble(totalOfferValue)},
                    ...sides
                ]
            }

            if (sides.length > 0 && isModified) {
                return [
                    // { title: "Сторон", value: `${sideCount} шт.` },
                    { title: "Бюджет", value: toRuble(totalValue) },
                    ...sides,
                ];
            }

            return [];

        },
    }
};
