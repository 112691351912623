<template>
    <Folder
        :id="folderId"
        :title="folderTitle"
        :width="folderWidth"
        :height="folderHeight"
        :top="folderTop"
        :left="folderLeft"
        :color="folderColor"
        :isRename="false"
    >
        <template v-slot:panel>
            <FolderControlPanel
                :isFound="true"
                :isAddAll="false"
                :isDuplicate="false"
                :isRemove="false"
                :isExport="false"
                @onFullSize="onFullSizeFolder"
                @onFiftySize="onFiftySizeFolder"
                @onCloseFolder="closeFolder(folderId)"
            />
        </template>

        <template v-slot:content>
            <KitTab
                :folderId="folderId"
                :canCheck="true"
                :canCheckAll="false"
                :columns="tableColumns"
                :modifieredCols="tableColumnsModifered"
                :tableData="tableRows"
                :rowsSelected="tableRowsIdsSelected"
                :style="{'height': '100%'}"
                @lazyload="onLazyloadTableRows"
                @filter="onFilterTable"
                @sort="onSortTable"
                @onCheckRow="onCheckTableRow"
                @onClickCell="onClickTableCell"
            />
        </template>
    </Folder>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Folder from '@/components/Folder/Folder.vue';
import FolderControlPanel from '@/components/Folder/FolderControlPanel.vue';
import KitTab from '@/components/HandbookComponents/KitTab.vue';
import ServiceTableWithoutPrice from '@/services/ServicesTable/ServiceTableWithoutPrice.js';
export default {
    name: 'LayoutFolderWithoutPrices',
    components: {
        Folder,
        FolderControlPanel,
        KitTab
    },
    props: {
        folderId: {
            type: String,
            default: ''
        },
        folderWidth: {
			type: String,
			default: '800px'
		},
		folderHeight: {
			type: String,
			default: '300px'
		},
        folderTop: {
            type: String,
            default: '10px'
        },
        folderLeft: {
            type: String,
            default: '10px'
        },
        folderColor: {
            type: String,
            default: ''
        },
        tableColumns: {
            type: Array,
            default: () => ([])
        },
        tableRowsCount: {
            type: Number,
            default: 0
        },
        tableRowsIdsSelected: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        folderTitle: 'Без цен',
        tablePagination: {
            page: 1,
            perPage: 40
        },
        tableFilter: {},
        tableSort: {
            column: '',
            type: ''
        },
        tableRows: []
    }),
    computed: {
        ...mapState({ filterSelectedData: state => state.filter.filterSelectedData }),
        isFilterWithoutPrices() {
            return !!this.filterSelectedData?.without_prices ?? false
        },
        tablePaginationPageTotal() {
            return Math.ceil(this.tableRowsCount / this.tablePagination.perPage);
        },
        tableColumnsModifered() {
            return this.tableColumns.filter(column => !!column?.isModifiered).map(column => String(column?.prop ?? ''));
		},
        tableColumnsDates() {
            return this.tableColumns.filter(column => !!column?.isDate).map(column => String(column?.prop ?? ''));
        }
    },
    watch: {
        filterSelectedData() {
            if (this.isFilterWithoutPrices)
                this.getTableRows();
        }
    },
    methods: {
        ...mapMutations('folders', ['closeFolder', 'toggleResizeFolder']),
        onFullSizeFolder() {
            this.toggleResizeFolder({ id: this.folderId, resize: 'full' });
        },
        onFiftySizeFolder() {
            this.toggleResizeFolder({ id: this.folderId, resize: 'fifty' });
        },
        async getTableRows(isClearTableRows = false) {
            const tableRows = await ServiceTableWithoutPrice.getTableWithoutPrices({
                filterMain: this.filterSelectedData,
                tablePagination: this.tablePagination,
                tableFilter: this.tableFilter,
                tableSort: this.tableSort
            }) ?? [];

            if (isClearTableRows)
                this.tableRows = [];
            
            this.tableRows.push(...tableRows);
        },
        onLazyloadTableRows() {
            this.tablePagination.page += 1;

            if (this.tablePagination.page <= this.tablePaginationPageTotal)
                this.getTableRows(false);
        },
        onFilterTable(tableFilter = {}) {
            this.tableFilter = { ...tableFilter };
            this.tablePagination.page = 1;

            this.getTableRows(true);
        },
        onSortTable(tableSort = {}) {
            this.tableSort.column = String(tableSort?.key ?? '');
            this.tableSort.type = String(tableSort?.type ?? '');
            this.tablePagination.page = 1;

            this.getTableRows(true);
        },
        onCheckTableRow(tableCheckData = {}) {
            this.$emit('checkTableRow', tableCheckData);
        },
        onClickTableCell(tableCell = {}) {
            this.$emit('clickTableCell', tableCell);
        }
    }
}
</script>