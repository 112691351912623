<template>
    <div
        class="table"
        :style="{ gridTemplateColumns: tableGridTemplateColumns }"
    >
    <div
        class="table__header-columns"
        :style="headerColumnStyle"
    >
			<div
				v-for="headerColumn of headerColumnsFiltered"
				:key="headerColumn.id"
				:class="headerColumn.class"
			>
				{{headerColumn.value}}
			</div>
		</div>

		<div
            :style="[styleHeaderMonths, monthsHeaderStyle]"
			class="table__header months"
		>
			<div
				v-for="(date, dateIndex) of filterSelectedDates"
				:key="dateIndex"
				class="months__item"
			>
				{{ getMonthName(date) }}
			</div>
		</div>

		<div class="sides">
			<div
				v-for="(priceData, index) of prices"
				:key="index"
				class="sides__item"
                v-tooltip="getTooltipText(priceData)"
			>
				{{ priceData.sideName }}
			</div>
		</div>

		<div class="gids">
			<div
				v-for="(priceData, index) of prices"
				:key="index"
				class="gids__item"
			>
				<div
                    v-tooltip="getTooltipText(priceData)"
                    class="gids__item-label"
                >
					{{ priceData.gid }}
				</div>
			</div>
		</div>


        <div
            v-for="(field, fieldIndex) of videoFields"
            :key="fieldIndex"
        >
            <VideoColumn
                :prices="prices"
                :field="field"
            />
        </div>

		<div class="table__values values">
			<div
				v-for="(priceData, index) of prices"
				:key="index"
				class="values__row"
			>
				<div
					v-for="(date, dateIndex) of filterSelectedDates"
                    :key="dateIndex"
					class="value"
					:class="getClasses(date, priceData.prices, priceData)"
					@click="onCheckPrice(date, priceData)"
				>
					{{ getPrice(date, priceData.prices) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UtilDate from '../../../../utils/UtilDate';
import VideoColumn from "./Assets/VideoColumn";

export default {
	name: "Table",
    components: {
        VideoColumn
    },
	props: {
		prices: {
			type: Array,
			default: () => []
		},
        selectedData: {
            type: Object,
            default: () => ({})
        },
		filterSelectedDates: {
			type: Array,
			default: () => ([])
		},
        isDigital: {
            type: Boolean,
            default: false
        },
        isDynamic: {
            type: Boolean,
            default: false
        }
	},
	data() {
		return {
			// months: months,
			headerColumns: [
				{
					id: 'side',
					value: 'Ст.',
					class: 'table__header-columns-side'
				},
				{
					id: 'gid',
					value: 'GID',
					class: 'table__header-columns-gid'
				},
                {
                    id: 'videoTime', // New header column
                    value: 'Длина ролика',
                    class: 'table__header-columns-videoTime',
                    isStatic: false
                },
                {
                    id: 'videoBlockOutputs', // New header column
                    value: 'Выходов в блоке',
                    class: 'table__header-columns-videoTime',
                    isStatic: false
                },
				// {
				// 	id: 'updated',
				// 	value: 'Обновлено',
				// 	class: 'table__header-columns-gid'
				// }
			],
			selectedDataBuffer: {}
		}
	},
	computed: {
        tableGridTemplateColumns() {
            const baseCols = ['50px', '100px']; // side + gid
            const videoCols = this.videoFields.map(() => '50px');
            // const dynamicCols = this.filterSelectedDates.map(() => '78px');

            // return [...baseCols, ...videoCols, ...dynamicCols].join(' ');

			return [...baseCols, ...videoCols, '1fr'].join(' ');
        },

        baseColumnCount() {
            return 2 + this.videoFields.length; // side + gid + videoFields
        },

        headerColumnStyle() {
            return {
                gridColumn: `1 / ${this.baseColumnCount + 1}`
            };
        },

        monthsHeaderStyle() {
            return {
                gridColumn: `${this.baseColumnCount + 1} / -1`
            };
        },

        videoFields() {
            if(this.isDigital){
                return ['videoTime', 'videoBlockOutputs'];
            }

            // if(this.isDynamic){
            //     return ['videoBlockOutputs'];
            // }

            return [];
        },

        headerColumnsFiltered() {
            return this.headerColumns.filter((headerColumn) => {
                const {id = null, isStatic = true} = headerColumn;
                if(!isStatic && !this.videoFields.includes(id)) {
                    return false;
                }

                return true;
            });
        },

		styleHeaderMonths() {
			return  {
                'grid-template-columns': `repeat(${this.filterSelectedDates.length}, 78px)`,
                'max-width': `${this.filterSelectedDates.length * 78}px`
            };
		},

		months() {
			return UtilDate.getMonthNameListFomCurrent();
		}
	},
	watch: {
		selectedData() {
			this.selectedDataBuffer = {...this.selectedData};
		}
	},
	mounted() {
		this.selectedDataBuffer = {...this.selectedData};
	},
	methods: {
		getMonthName(date = '') {
			// return `${UtilDate.getMonthsName(date)}, без НДС`;
			return UtilDate.getMonthsName(date);
		},
		getMonthIndex(month = '') {
			return UtilDate.getMonthIndex(month);
		},

        // isSelected(monthIndex, priceData) {
        //     const {prices = [], sideId = '-1'} = priceData;
        //     const pricesSelected = this.selectedDataBuffer[sideId] || [];
        //     const monthNumber = monthIndex + 1;
        //     const price = prices.find(item => item.month === monthNumber) || {};
        //     const {id = '-1'} = price;
        //     return pricesSelected.includes(String(id));
        // },

		isSelected(date = '', priceData = {}) {
            const {prices = [], sideId = '-1'} = priceData;
            const pricesSelected = this.selectedDataBuffer[sideId] || [];
            const priceItem = prices.find(item => String(item?.date ?? '') === String(date)) ?? {};
            const {id = '-1'} = priceItem;
            return pricesSelected.includes(String(id));
        },

		isActual(date = '', priceData = {}) {
            const {prices = []} = priceData;
            const priceItem = prices.find(item => String(item?.date ?? '') === String(date)) ?? {};
            const {is_actual = "1"} = priceItem;
            return is_actual == "1";
        },

		getPrice(date = '', prices = []) {
			const priceItem = prices.find(item => String(item?.date ?? '') === String(date)) ?? {};
			return String(priceItem?.priceMonth ?? '0');
		},

		getStatus(date, prices) {
			const priceItem = prices.find(item => String(item?.date ?? '') === String(date)) ?? {};
			return String(priceItem?.status ?? '');
		},

		getClasses(date = '', prices = [], priceData = {}) {
			const isEmpty = this.getStatus(date, prices) === 'empty';
			const isFree = this.getStatus(date, prices) === 'free';
			const isReserved = this.getStatus(date, prices) === 'reserved'
			const isSelected = this.isSelected(date, priceData);
			const isBooked = this.getStatus(date, prices) === 'busy';
			const isDismantling = this.getStatus(date, prices) === 'dismantling';
			const isActual = this.isActual(date, priceData);

			return {
				value_empty: isEmpty,
				value_free: isFree && isActual,
				value__booked: isBooked && isActual,
				value__reserved: isReserved && isActual,
                value__dismantling: isDismantling && isActual,
                value__not_actual: !isActual,
				selected: isSelected,
				value__busy: isBooked
			};
		},

		onCheckPrice(date = '', priceData = {}) {
            const{prices = [], sideId = '-1'} = priceData;
            const priceItem = prices.find(item => String(item?.date ?? '') === String(date)) ?? {};
            if(Object.keys(priceItem).length > 0){
                const {id = '-1'} = priceItem;
                this.$emit('onCheckPrice', id, sideId);

				this.checkPrice(date, priceData, id, sideId);
            }
        },

		checkPrice(date = '', priceData = {}, priceId = '', sideId = '') {
			const isSelected = this.isSelected(date, priceData);
			let pricesIds = this.selectedDataBuffer[sideId] ?? [];

			if (isSelected) {
				const index = pricesIds.findIndex(priceIdItem => String(priceId) === String(priceIdItem));
				if (index !== -1)
					pricesIds.splice(index, 1)
			} else
				pricesIds.push(priceId);

			this.selectedDataBuffer = {
				...this.selectedDataBuffer,
				[sideId]: pricesIds
			};
		},

        /**
         *
         * @param {Object} priceData
         * @returns {string}
         */
        getTooltipText(priceData) {
            return 'Обновлено ' + String(priceData?.updatedAt ?? '');
        }
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#6EC87A !default;
$reserved-color:			#FFB300 !default;
$dismantling-color:			#000000 !default;
$booked-color:              #00d2ff !default;
$not-actual-color: #184e00 !default;

.table {
	$size: 50px;
	$height: 30px;
	$indent: 11px;
	$cellIndent: 4px;

	display: inline-grid;
	//grid-template-columns: $size 100px 50px 50px 1fr;
	grid-template-rows: $size auto;
	grid-gap: $indent;
    width: auto;

	&__header {
		//grid-column: 5;
		background: $primary-color;
		border-radius: 4px;
		// padding-left: $size + $indent;

		&-columns {
			display: grid;
			grid-template-columns: 50px 100px 50px 50px;
			gap: 11px;
			background: #F5F5F5;
			//grid-column: 1/5;
			border-radius: 4px;

			@mixin table__header-columns-item {
				display: flex;
				align-items: center;
				font-size: 12px;
				color: #ADB0BB;
			}

			&-side {
				@include table__header-columns-item;
				justify-content: center;
			}

			&-gid {
				@include table__header-columns-item;
				justify-content: start;
				padding: 0 10px;
			}

            &-videoTime {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: $assistant-color;
            }
		}
	}
	.months {
		display: grid;
		// grid-template-columns: repeat(12, 1fr);

		&__item {
            width: 78px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: $assistant-color;
		}
	}

	@mixin vertical-header {
		background: $primary-color;
		border-radius: 4px;
	}

	@mixin vertical-header__item {
		height: $height;
		display: flex;
		align-items: center;
		color: $assistant-color;

		&:not(:first-child) {
			margin-top: $cellIndent;
		}
	}

	.sides {
		@include vertical-header;

		&__item {
			@include vertical-header__item;
			width: $size;
			justify-content: center;
		}
	}

	.gids, .updatedAt {
		@include vertical-header;

		&__item {
			@include vertical-header__item;
			width: 100px;
			padding: 0 10px;

			&-label {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	// &__values {}
	.values {
		&__row {
			display: flex;
			justify-content: space-between;

			&:not(:first-child) {
				margin-top: $cellIndent;
			}
		}

		.value {
            cursor: pointer;
			// width: $size;
			width: 74px;
			height: $height;
			border: 2px solid;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $assistant-color;
			transition: .2s;

			&:hover {
				color: #000;
			}

			&:not(:last-child) {
				margin-right: $cellIndent;
			}

			&_empty {
				background: $primary-lighten-color;
				border-color: $primary-lighten-color;
			}
			&_free {
				font-weight: 500;
				border-color: $success-lighten-color;

				&.selected {
					background: $success-lighten-color;
					color: #000;
				}
			}
			&__booked {
				font-weight: 500;
				border-color: $booked-color;

				&.selected {
					background: $booked-color;
					color: #000;
				}
			}
            &__reserved {
                font-weight: 500;
                border-color: $reserved-color;

                &.selected {
                    background: $reserved-color;
                    color: #000;
                }
            }
            &__dismantling {
                font-weight: 500;
                border-color: $dismantling-color;

                &.selected {
                    background: $dismantling-color;
                    color: #ffffff;
                }
            }
            &__not_actual{
                font-weight: 500;
                // border-color: $assistant-color;
				border-color: $danger-color;
                color: $assistant-color;

                &.selected {
                    // background: $assistant-color;
					border-color: $danger-color;
                    color: #000;
                }
            }

			// &__busy {
			// 	text-decoration: line-through;
			// }
		}
	}
}
</style>
