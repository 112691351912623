import ServiceApi from '@/services/ServiceApi.js';

export default class ServiceKitSideTag {
    static _microserviceName = 'free';

    static _requestRoutes = {
        getTagList: '/free/side-tag-list',
        createTag: '/free/side-tag-create',
        deleteTags: '/free/side-tag-delete',
        toggleLinkTags: {
            link: '/free/side-tag-link',
            unlink: '/free/side-tag-unlink'
        }
    };

    static async getTagList({ page = 1, perPage = 20, searchQuery = '', kitId = '' }) {
        return this._getTagList({ page, perPage, searchQuery, kitId });
    }

    static async _getTagList({ page = 1, perPage = 20, searchQuery = '', kitId = '' }) {
        const requestQueryParams = this._getTagListBefore({ page, perPage, searchQuery, kitId });

        try {
            const responseBody = await ServiceApi.get(this._microserviceName, this._requestRoutes.getTagList, requestQueryParams);
            const tagListData = responseBody?.data?.data ?? {}; 
            const tagListDataAfter = this._getTagListAfter({ tagListData, perPage });

            return tagListDataAfter;
        } catch (error) {
            const errorMessage = ServiceApi.getErrorMessage(error, '');
            
            if (errorMessage)
                throw errorMessage;
            else
                console.log(error);
        }
    }

    static _getTagListBefore({ page = 1, perPage = 20, searchQuery = '', kitId = '' }) {
        const requestQueryParams = {
            'page': parseInt(page),
            'per-page': parseInt(perPage),
            'searchQuery': String(searchQuery),
            'setId': String(kitId)
        };

        return requestQueryParams;
    }

    static _getTagListAfter({ tagListData = {}, perPage = 20 }) {
        const tagList = tagListData?.items?.map(tagData => this._createTagAfter(tagData)) ?? [];
        const pageCount = parseInt(perPage) === 0 ? 1 : Math.ceil(parseInt(tagListData?.totalCount ?? 0) / parseInt(perPage));
        const tagListDataAfter = { tagList, pageCount };

        return tagListDataAfter;
    }

    static async createTag(tagName = '') {
        return this._createTag(tagName);
    }

    static async _createTag(tagName = '') {
        const requestBody = this._createTagBefore(tagName);

        try {
            const responseBody = await ServiceApi.post(this._microserviceName, this._requestRoutes.createTag, requestBody);
            const tagData = responseBody?.data?.data ?? {};
            const tag = this._createTagAfter(tagData);

            return tag;
        } catch (error) {
            const errorMessage = ServiceApi.getErrorMessage(error, '');

            if (errorMessage)
                throw errorMessage;
            else
                console.log(error);
        }
    }

    static _createTagBefore(tagName = '') {
        const requestBody = { name: String(tagName) };
        
        return requestBody;
    }

    static _createTagAfter(tagData = {}) {
        const tag = { 
            id: parseInt(tagData?.id ?? -1),
            value: String(tagData?.name ?? ''),
            extraData: { ...tagData },
        };

        return tag;
    }

    static async deleteTags(tagsIds = []) {
        return this._deleteTags(tagsIds);
    }

    static async _deleteTags(tagsIds = []) {
        const requestBody = this._deleteTagsBefore(tagsIds);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRoutes.deleteTags, requestBody);
        } catch (error) {
            const errorMessage = ServiceApi.getErrorMessage(error, 'Не удалось удалить теги');

            if (errorMessage)
                throw errorMessage;
            else
                console.log(error);
        }
    }

    static _deleteTagsBefore(tagsIds = []) {
        const requestBody = { side_tag_ids: tagsIds.map(tagId => parseInt(tagId)) };

        return requestBody;
    }

    static async toggleLinkTags({ action = 'link', tagsIds = [], sidesIds = [], kitId = '', isSelectedAll = false }) {
        return this._toggleLinkTags({ action, tagsIds, sidesIds, kitId, isSelectedAll });
    }

    static async _toggleLinkTags({ action = 'link', tagsIds = [], sidesIds = [], kitId = '', isSelectedAll = false }) {
        const requestBody = this._toggleLinkTagsBefore({ action, tagsIds, sidesIds, kitId, isSelectedAll });

        try {
            await ServiceApi.post(this._microserviceName, this._requestRoutes.toggleLinkTags[action] ?? '', requestBody);
        } catch (error) {
            const errorMessage = ServiceApi.getErrorMessage(error, '');

            if (errorMessage)
                throw errorMessage;
            else
                console.log(error);
        }
    }

    static _toggleLinkTagsBefore({ tagsIds = [], sidesIds = [], kitId = '', isSelectedAll = false }) {
        const requestBody = {
            side_tag_ids: tagsIds.map(tagId => parseInt(tagId)),
            side_ids: sidesIds.map(sideId => parseInt(sideId)),
            set_id: parseInt(kitId),
            isSelectedAll: Boolean(isSelectedAll)
        };

        return requestBody;
    }
}