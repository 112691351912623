<template>
    <label class="base-switch">
        <input
            v-model="modelProxy"
            type="checkbox"
        />

        <span class="base-switch__slider" />
    </label>
</template>

<script>
export default {
    name: 'BaseSwitch',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    computed: {
        modelProxy: {
            get() {
                return this.value;
            },
            set(valueNew = false) {
                this.$emit('change', valueNew);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
            margin: 0;
            cursor: pointer;
        }

        &__slider {
            position: absolute;
            cursor: pointer;
            top: 0; 
            left: 0; 
            right: 0; 
            bottom: 0;
            background-color: #b9bbc2;
            transition: 0.4s;
            border-radius: 20px;

            &::before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 2px;
                top: 2px;
                background-color: #fff;
                transition: 0.4s;
                border-radius: 50%;
            }
        }
    }

    input {
        &:checked + .base-switch__slider {
            background-color: #4A92F6;
        }

        &:checked + .base-switch__slider:before {
            transform: translateX(17px);
        }
    }
</style>