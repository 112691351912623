import ServiceApi from "@/services/ServiceApi.js";

export default class ServiceTableWithoutPrice {
    static _microserviceName = 'free';

    static _requestRoutes = { getTableWithoutPrices: '/map/table-without-prices' };

    static async getTableWithoutPrices({ filterMain = {}, tablePagination = {}, tableFilter = {}, tableSort = {} }) {
        return this._getTableWithoutPrices({ filterMain, tablePagination, tableFilter, tableSort });
    }

    static async _getTableWithoutPrices({ filterMain = {}, tablePagination = {}, tableFilter = {}, tableSort = {} }) {
        const requestBody = this._getTableWithoutPricesBefore({ filterMain, tablePagination, tableFilter, tableSort });

        try {
            const responseBody = await ServiceApi.post(this._microserviceName, this._requestRoutes.getTableWithoutPrices, requestBody);
            const tableRows = this._getTableWithoutPricesAfter(responseBody);

            return tableRows;
        } catch (error) {
            console.log(error);
        }
    }

    static _getTableWithoutPricesBefore({ filterMain = {}, tablePagination = {}, tableFilter = {}, tableSort = {} }) {
        const tableFilterBefore = Object.keys(tableFilter).map(columnKey => ({ column: columnKey, values: [tableFilter[columnKey] ?? ''] }));

        const requestBody = {
            filter_params: filterMain,
            pagination: {
                page: parseInt(tablePagination?.page ?? 1),
                per_page: parseInt(tablePagination?.perPage ?? 40)
            },
            filter: tableFilterBefore,
            sort: tableSort
        };

        return requestBody;
    }

    static _getTableWithoutPricesAfter(responseBody = {}) {
        const tableRows = responseBody?.data?.data?.rows ?? [];

        return tableRows;
    }
}