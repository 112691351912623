const schemeWidgetMap = {
    containerPoi: {
        headerDropdownItems: [
            {
                name: "Закрыть POI",
                emit: "onClosePoi",
            },
            {
                name: "Удалить всё",
                emit: "onRemoveAll",
            },
        ],
        headerCreateDropdownItems: [
            {
                name: "Из системы",
                emit: "onAddNewGroup",
            },
            {
                name: "Мои точки",
                emit: "onAddNewMoiPoiGroup",
            },
        ],

        groupMenuItems: [
            {
                name: "Удалить группу",
                emit: "removeGroupPoi",
            },
        ],

        poiMenuItems: [
            // {
            //     name: 'Удалить метку',
            //     emit: 'removePoi',
            // },
        ],

        poiTabsList: [
            {
                name: "Oohdesk POI",
                id: "oohdesk",
            },
            {
                name: "Мои POI",
                id: "moi",
            },
            {
                name: "Из файла",
                id: "file",
            },
            {
                name: "Пустая группа",
                id: "empty",
            },
        ],

        addGroupModal: {
            textAddPoi: "Добавить POI",
            textGroupName: "Введите название группы, для отображения в списке",
            textGroupNamePlaceholder: "Пример: Рестораны",
            textQueryName: "Введите название того, что хотите найти",
            textQueryNamePlaceholder: "Пример: Вкусно и точка",
            textSelectColorAndIcon:
                "Выберите цвет или иконку для точек на карте",
            textAddFile: "Выберите файл",
            textAddMoiPointTitle: "Добавить точку на карте",
            textAddMoiPointInput: 'Адрес (в диапазоне "Москва")',
            textAddMoiPointPlaceholder: "Например: улица Пушкина, дом 42",
            textAddPoint: "Создать точку POI",
            textCancel: "Отменить",
            textApply: "Добавить",
            textStartCreateMoiPoint: "Перейти к добавлению точек",
            textUpload: "Загрузить",
        },

        addMoiPoiPointsModal: {
            textAddPoi: 'Добавить точку на карте',
            textInputLabel: 'Адрес (в диапазоне "Москва")',
            textPlaceholder: 'Например: улица Пушкина, дом 42',
            textAddPoint: 'Создать точку POI'
        },
    },
};

export default schemeWidgetMap;
