<template>
	<div class="all">
		<AppTable
			primaryKey="id"
            :canCheck="canCheck"
            :canCheckAll="canCheckAll"
			:columns="columns"
			:rows="filteredRows"
			:isProgressBar="isProgressBarLoading"
            :isStickyProgressBar="true"
            :rowsSelected="rowsSelectedBuffer"
            :rowsSelectedPart="rowsSelectedPart"
            :is-virtual-scroll="true"
            :virtual-scroll-config="getScrollConfig"
            :isStickyCheckbox="isStickyCheckbox"
			class="all-table"
			@lazyload="onLazyload"
			@sort="onSort"
			@search="onFilter"
            @onCheckRow="onCheckRow"
            @onCheckRowsAll="onCheckRowsAll"
            @onClickCell="onClickCell"
            @onScroll="onScrollTable"
		>
			<template
				v-for="(col,x) of [...modifieredCols, ...datesCols]"
				v-slot:[col]="scopeData"
			>
				<BaseCell
                    v-if="modifieredCols.includes(col)"
					:key="x"
					:content="scopeData.row[col]"
                    :short-content="baseCellContent(scopeData.row[col], scopeData.column.maxLength)"
                    :is-show-tooltip="showTooltip(col, scopeData.row[col], scopeData.column.maxLength)"
				/>
                <div
                    v-if="datesCols.includes(col)"
                    :key="x"
                    class="cell-date-wrap"
                    v-tooltip="getPricePartInfo({ row: scopeData.row, column: scopeData.column })"
                >
                    <label class="checkbox-label">
						<div class="cell-checkbox">
							<base-checkbox-v2
								:key="x"
								:checked="getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})"
								@change.native="onCheckSubCheckbox({row: scopeData.row, column: scopeData.column})"
							/>
						</div>
						<div
							class="cell-checkbox2"
							:class="{'cell-checkbox2_active': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})}"
                            :style="{
                                'border-color': isPriceNumber(scopeData.row[col]) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : getActualityColor({row: scopeData.row, column: scopeData.column}),
                                'background': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'color': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? '#000' : '#ADB0BB',
                                // 'text-decoration': isPriceBusy({row: scopeData.row, column: scopeData.column}) ? 'line-through' : 'none'
                                'border': getPriceBorder({ row: scopeData.row, column: scopeData.column })
                            }"
						>
                            <IconAlarm
                                v-if="isPricePart({ row: scopeData.row, column: scopeData.column })"
                                class="cell-checkbox2__price-part"
                            />
							<span>
                                {{scopeData.row[col] | cutNumber}}
                            </span>
						</div>
					</label>
                </div>
			</template>

			<template v-slot:image_src="scopeData">
				<AppPhoto
					:small="scopeData.row.photo.small"
					:big="scopeData.row.photo.big"
                    class="all-table__photo"
				/>
			</template>

			<template v-slot:light="scopeData">
				<AppLight
                    :iconName="scopeData.row.light == 1 ? 'icon-light' : 'icon-light-no'"
                    :isLight="scopeData.row.light == 1" />
			</template>

			<template v-slot:country_id="scopeData">
				<CountryFlag :abbreviation="scopeData.row.country_id" />
			</template>

			<template v-slot:oohdesk_id="scopeData">
				<AppIdentifier :firstId="scopeData.row.ooh_marker_id" :secondId="scopeData.row.oohdesk_id" />
			</template>

            <template v-slot:tax="scopeData">
                <div class="cell-tax">
                    {{scopeData.row.tax == '1' ? 'Да' : 'Нет'}}
                </div>
            </template>

			<template v-slot:image_link="scopeData">
				<AppLink :link="scopeData.image_link" />
			</template>

			<template v-slot:image_map="scopeData">
				<MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat" />
			</template>

            <template v-slot:supplier_link="scopeData">
                <AppLink
                    content="Перейти"
                    :link="scopeData.row.supplier_link"
                />
            </template>

            <template v-slot:sideTags="slotPropsTableData">
                <BaseSelectMultipleV2
                    :view="'tags'"
                    :position="'right'"
                    :canSearch="true"
                    :isOptionsFullWidth="true"
                    :isActionCreate="true"
                    :isActionRemove="true"
                    :options="tagList"
                    :value="getTagsSelected(slotPropsTableData)"
                    :style="{ width: '100%', height: '100%' }"
                    :chipsAreaWidth="220"
                    @apply="onSelectTags($event, slotPropsTableData)"
                    @create="onCreateTag($event, slotPropsTableData)"
                    @remove="onDeleteTags"
                />
            </template>


            <template v-slot:more_btn="scopeData">
                <div class="cell-freeze">
                    <BaseDropdown
                        class="cell-dropdown"
                        :padding="0"
                        :is-fixed="false"
                        position="right"
                        :style-list="dropdownStyles"
                        @changeVisible="onChangeVisibleDropdown"
                    >
                        <template #preview>
                            <BaseButtonIcon
                                class="table-dropdown-button cell-dropdown__prev-btn"
                                iconName="tabler-icon-dots-vertical"
                                tooltipText=""
                            />
                        </template>

                        <template #content v-if="isShowDropdown">
                            <div class="cell-dropdown__container">
                                <BaseButton
                                    v-if="folderId === '0'"
                                    view="simple"
                                    form="oval"
                                    size="small"
                                    @click="onAddPricesRow({ row: scopeData.row, column: scopeData.column })"
                                >Добавить</BaseButton>
                                <BaseButton
                                    v-else
                                    view="simple"
                                    form="oval"
                                    size="small"
                                    @click="onRemovePricesRow({ row: scopeData.row, column: scopeData.column })"
                                >Удалить</BaseButton>
                            </div>
                        </template>
                    </BaseDropdown>
                </div>
            </template>
		</AppTable>
	</div>
</template>

<script>
import MixinHandbookTab from '@/mixins/MixinsHandbook/MixinHandbookTab';
import AppTable from '@/components/Table2/Table';
import AppPhoto from '@/components/CellComponents/Photo.vue';
import CountryFlag from '@/components/CellComponents/CountryFlag.vue';
import AppLight from '@/components/CellComponents/Light.vue';
import AppIdentifier from '@/components/CellComponents/Identifier.vue';
import AppLink from '@/components/CellComponents/Link.vue';
import BaseCell from '@/components/CellComponents/BaseCell.vue';
import MapImage from '@/components/CellComponents/MapImage.vue';
import BaseCheckboxV2 from '@/components/Base/BaseCheckboxV2';
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseButton from "@/components/Base/BaseButton";
import IconAlarm from '../../components/Icons/IconAlarm.vue';
import BaseSelectMultipleV2 from '@/components/Base/BaseSelectMultipleV2.vue';

export default {
	name: "KitTab",
    mixins: [MixinHandbookTab],
	components: {
		AppTable,
		AppPhoto,
		CountryFlag,
		AppLight,
		AppIdentifier,
		AppLink,
		BaseCell,
		MapImage,
        BaseCheckboxV2,
        BaseButton,
        BaseDropdown,
        BaseButtonIcon,
        IconAlarm,
        BaseSelectMultipleV2
	},
	props: {
        datesCols: {
            type: Array,
			default: () => ([])
        },
        rowsSelected: {
            type: Array,
			default: () => ([])
        },
        rowsCheckboxes: {
            type: Object,
            default: () => ({})
        },
        rowsSelectedPart: {
            type: Array,
            default: () => ([])
        },
		folderId: {
			type: [Number, String],
			required: true
		},
        canCheck: {
            type: Boolean,
            default: false
        },
        canCheckAll: {
            type: Boolean,
            default: true
        },
        height: {
            type: Number,
            default: 0
        },
        isVirtualScroll: {
            type: Boolean,
            default: false
        },
        tagList: {
            type: Array,
            default: () => ([])
        },
        isStickyCheckbox: {
            type: Boolean,
            default: false
        }
	},
    data: () => ({
        scrollTop: 0,
        rowHeight: 40,
        isShowDropdown: false,
        dropdownStyles: {
            minWidth: '100px',
            top: '0',
            right: '45px',
            boxShadow: '0 0 10px 0 #aaa',
        },
        rowsCheckboxesBuffer: {},
        rowsSelectedBuffer: [],
    }),
    computed: {
        filteredRows() {
            if (this.isVirtualScroll && this.tableData.length > 0) {
                return this.tableData.slice(this.nodeIndexStart, this.nodeIndexEnd);
            }

            return this.tableData;
        },
        tableBodyHeight() {
            const tableHeader = 58;
            return this.height > 0 ? this.height - tableHeader : 0;
        },
        nodeIndexStart() {
            const visibleRows = Math.ceil(this.tableBodyHeight / this.rowHeight);
            const nodeIndexStart = Math.floor(this.scrollTop / this.rowHeight) - (visibleRows * 3);

            return Math.max(0, nodeIndexStart);
        },
        nodeIndexEnd() {
            const visibleRows = Math.ceil(this.tableBodyHeight / this.rowHeight);
            const nodeIndexEnd = this.nodeIndexStart + visibleRows * 5;

            return Math.min(nodeIndexEnd, this.tableData.length);
        },
        spacerTopStyle() {
            return `${this.nodeIndexStart * this.rowHeight}px`;
        },
        spacerBottomStyle() {
            return `${(this.tableData.length - this.nodeIndexEnd) * this.rowHeight}px`;
        },
        getScrollConfig() {
            if (!this.isVirtualScroll) {
                return {};
            }

            return {
                rowHeight: this.rowHeight,
                spacerStyle: {
                    'padding-top': this.spacerTopStyle,
                    'padding-bottom': this.spacerBottomStyle
                }
            };
        },
    },
    watch: {
        rowsCheckboxes() {
            this.rowsCheckboxesBuffer = {...this.rowsCheckboxes};
        },
        rowsSelected() {
            this.rowsSelectedBuffer = [...this.rowsSelected];
        }
    },
    mounted() {
        this.rowsCheckboxesBuffer = {...this.rowsCheckboxes};
        this.rowsSelectedBuffer = [...this.rowsSelected];
    },
    methods: {
        onCheckRow(checkData = {}) {
            this.$emit('onCheckRow', checkData);
        },
        onCheckRowsAll(isSelected = false) {
            this.$emit('onCheckRowsAll', isSelected);
        },
        onClickCell(data){
            data.kitId = this.folderId;
            this.$emit('onClickCell', data);
        },
        onCheckSubCheckbox(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {allPrices = null, prices = []} = row;
            const {prop = '', isCanCheck = false} = column;
            const propSplitted = String(prop).split('__');
            const propDate = propSplitted[0];
            const currentPrices = allPrices === null ? Object.assign([], prices) : Object.assign([], allPrices);
            const price = currentPrices.find(price => String(price.date) === String(propDate)) || {};
            if(Object.keys(price).length && isCanCheck){
                this.$emit('onCheckSubCheckbox', checkData);

                const isPriceBusy = String(price?.status ?? '') === 'busy';
                if (!isPriceBusy)
                    this.checkSubCheckbox(checkData);
            }

        },
        checkSubCheckbox(checkData = {}) {
            const isChecked = this.getCheckedSubCheckbox(checkData);
            const {column = {}, row = {}} = checkData;
            const {prop: priceProp = ''} = column;
            const {id: rowId = ''} = row;
            let pricesProp = this.rowsCheckboxesBuffer[rowId] ? [...this.rowsCheckboxesBuffer[rowId]] : [];
            if (isChecked) {
                const indexPriceProp = pricesProp.findIndex(pricePropItem => String(priceProp) === String(pricePropItem));
                if (indexPriceProp !== -1)
                    pricesProp.splice(indexPriceProp, 1);

                if (pricesProp.length === 0) {
                    const indexRowId = this.rowsSelectedBuffer.findIndex(rowSelectedId => String(rowId) === String(rowSelectedId));
                    if (indexRowId !== -1)
                        this.rowsSelectedBuffer.splice(indexRowId, 1);
                }
            } else {
                pricesProp.push(String(priceProp));
                if (pricesProp.length === 1)
                    this.rowsSelectedBuffer.push(String(rowId));
            }

            this.rowsCheckboxesBuffer = {
                ...this.rowsCheckboxesBuffer,
                [rowId]: pricesProp
            };
        },
        getCheckedSubCheckbox(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {prop = ''} = column;
            const {id: rowId = -1} = row;
            const cellsChecked = this.rowsCheckboxesBuffer[rowId] || [];
            return cellsChecked.includes(prop);
        },
        isPriceNumber(price = '') {
            return price !== '' && price !== null && !isNaN(price);
        },
        onScrollTable(event) {
            // сделать emit
            if (this.isVirtualScroll) {
                const {currentTarget = {}} = event;
                const {scrollTop = 0} = currentTarget;
                this.scrollTop = scrollTop;
            }
        },
        onAddPricesRow(checkData) {
            this.isShowDropdown = false;
            this.$emit('onAddPricesRow', checkData);
        },
        onRemovePricesRow(checkData) {
            this.isShowDropdown = false;
            this.$emit('onRemovePricesRow', checkData);
        },
        onChangeVisibleDropdown(newVal) {
            this.isShowDropdown = newVal;
        },
        showTooltip(col, val, maxLength = 0) {
            const isSlicedString = this.hasSlicedBaseCell(val, maxLength);
            const slicedString = this.baseCellContent(val, maxLength);

            // 3 - троеточие в конце не считаем в длинну строки
            const slicedLength = isSlicedString ? slicedString.length - 3 : slicedString.length;
            return (
                (val !== null) &&
                (col === 'address' || col === 'gid') &&
                (val.length > slicedLength)
            );
        },
        baseCellContent(content, maxLength = 0) {
            if (content !== null && maxLength && (content.length > maxLength)) {
                const sliced = content.slice(0, maxLength);
                const result = `${sliced}...`;
                return result;
            }

            return content !== null ? String(content) : '';
        },
        hasSlicedBaseCell(content, maxLength = 0) {
            return content !== null && maxLength && (content.length > maxLength);
        },
        getTagsSelected(slotPropsTableData = {}) {
            return slotPropsTableData?.row?.sideTags?.map(tag => parseInt(tag?.id ?? -1)) ?? [];
        },
        onSelectTags(tagsIdsSelected = [], slotPropsTableData = {}) {
            const tagsIdsLinked = slotPropsTableData?.row?.sideTags?.map(tag => parseInt(tag?.id ?? -1)) ?? [];
            const sideId = parseInt(slotPropsTableData?.row?.id ?? '');

            this.$emit('selectTags', { tagsIdsSelected, tagsIdsLinked, sideId });
        },
        onCreateTag(tag = {}, slotPropsTableData = {}) {
            const sideId = parseInt(slotPropsTableData?.row?.id ?? '');

            this.$emit('createTag', tag, sideId);
        },
        onDeleteTags(tagsIds = []) {
            this.$emit('deleteTags', tagsIds);
        }
    },
	filters: {
		cutNumber(value = '') {
            const price = (value !== '' && value !== null && !isNaN(value)) ? value : '';
            if (price !== '') {
                const priceLocale = parseInt(price).toLocaleString('ru');
                // const priceLocaleArr = String(priceLocale).split(/\s/);
                // const priceFormatted = priceLocaleArr.length >= 2 ? `${priceLocaleArr[0]}${'к'.repeat(priceLocaleArr.length - 1)}` : `${priceLocaleArr[0]}`;
                // return priceFormatted;
                return priceLocale;
            }
		}
	}
}
</script>

<style lang="scss" scoped>
$active-color: #4A92F6 !default;

.all {
	display: flex;

	&-table {
		margin-top: 4px;

        &__photo {
            width: 34px;
            height: 34px;
        }

        ::v-deep .header {
            .format-cell .cell__content span,
            .ots-cell .cell__content span,
            .grp-cell .cell__content span,
            .date-cell .cell__content span {
                text-align: right !important;
            }

            .cell.date-cell {
                padding: 4px 15px !important;
            }
        }

        ::v-deep .body {
            .cell.address-cell .default-cell,
            .cell.direction-cell .default-cell,
            .cell.material-cell .default-cell {
                height: inherit;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }

            .cell.address-cell .default-cell {
                word-break: break-all;
            }
        }
	}

    .cell-date-wrap {
        width: 100%;
        height: 100%;
    }

    .cell-tax{
        width: 100%;
        text-align: center;
    }

	.checkbox-label {
		.cell-checkbox { display: none !important; }

		.cell-checkbox2 {
            width: 100%;
            height: 100%;
			display: flex;
			justify-content: end;
			align-items: center;
			cursor: pointer;
            padding: 13px 15px;
            border: 2px solid;


			&_active {
				background: $active-color;
				border-color: $active-color;
				color: #fff;
			}

            &__price-part {
                margin-right: 5px;
            }
		}
	}

    .cell-dropdown {
        ::v-deep .dropdown__triangle {
            top: 12px !important;
            right: -18px !important;
            border-left: 9px solid #fff !important;
            border-bottom-color: transparent !important;
            z-index: 6 !important;
        }

        ::v-deep .dropdown {
            box-shadow: 0 3px 15px #21242d17 !important;
        }

        &__container {
            position: relative;
            background: #fff;
            border-radius: 6px;
            overflow: hidden;
            z-index: 5;
            padding: 10px;

            button {
                padding: 5px 10px;
                font-weight: 500;
                color: #ADB0BB;
                transition: all .4s ease-out;

                &:hover {
                    color: #000;
                }
            }
        }
    }
}
</style>
