<template>
    <div class="layout-modules">
        <WidgetMenu
            class="widget-menu"
            :active-checkbox-list="widgetMenuLabels"
            orientation="vertical"
            :config-show-widgets="widgetMenuConfig"
            @onClickWidget="onClickWidget"
        />

        <container-poi
            v-show="activeWidgetName === 'poi'"
            ref="containerPoi"
            :clickedMoiPoiCoords="moiPoiCoords"
            :selectedCityFilter="filterCityId"
            @setActiveLabel="setActiveLabel"
            @onDrawPoiMarkers="onDrawPoiMarkers"
            @onFilterPoiRadius="onFilterPoiRadius"
            @onClearPoiMarkers="onClearPoiMarkers"
            @onUpdateColorMarkers="onUpdateColorMarkers"
            @onUpdateIconMarkers="onUpdateIconMarkers"
            @onTogglePoiMarker="onTogglePoiMarker"
            @changeActiveList="setActiveLabel"
            @onTogglePoiAddress="onTogglePoiAddress"
            @onClosePoi="onClosePoi"
        />
    </div>
</template>

<script>
import ContainerPoi from "../ContainerPoi/ContainerPoi";
import MixinApi from '@/mixins/MixinsPageBayuerdesk/MixinApi';
import {mapState} from "vuex";

export default {
    name: "LayoutModules",
    components: {
        ContainerPoi,
    },
    mixins: [
        MixinApi,
    ],
    props: {
        moiPoiCoords: {
            type: Array,
            default: () => []
        }
    },
    /**
     * Данные компонента
     * @property {String} activeWidgetName - Имя активного виджета
     * @property {Boolean} isActiveLabel - Показать/скрыть иконку галочки у первой панели
     */
    data: () => ({
        activeWidgetName: '',
        widgetMenuLabels: [],
        isActiveLabel: false,
        widgetMenuConfig: ['poi'],
    }),
    computed: {
        ...mapState({
            filterSelectedData: state => state.filter.filterSelectedData
        }),
        filterCityId() {
            const { city_id = []} = this.filterSelectedData;
            return city_id;
        },
    },
    methods: {
        /**
         * Клик по элементу меню
         * @param {String} emitName - Имя события кнопки
         * @param {Boolean} isActiveWidget - Включен/выключен элемент
         */
        onClickWidget(emitName = '', isActiveWidget = false) {
            if (emitName === 'onTogglePoi') {
                this.activeWidgetName = isActiveWidget ? 'poi' : '';
            }
        },

        /**
         * Показать лейбл, если есть выбранные группы
         * @property {String} widgetName - Имя виджета
         * @property {Boolean} isActive - Показать/скрыть лейбл выбранных групп
         */
        setActiveLabel(widgetName = '', isActive = false) {
            const widgetIndex = this.widgetMenuLabels.findIndex((el) => String(el) === String(widgetName));

            if (isActive && widgetIndex === -1) {
                this.widgetMenuLabels.push(widgetName);
            }

            if (!isActive && widgetIndex !== -1) {
                this.widgetMenuLabels.splice(widgetIndex, 1);
            }
        },

        /**
         * Показать все POI данной группы на карте
         * @param {Array} dataPoi - Список POI
         */
        onDrawPoiMarkers(dataPoi) {
            this.$emit('onDrawPoiMarkers', dataPoi);
        },

        /**
         * @param {Number} radius
         */
        onFilterPoiRadius(radius, directionType){
            this.$emit('onFilterPoiRadius', radius, directionType);
        },

        /**
         * Удалить все маркеры группы с карты
         * @param {String} groupId
         */
        onClearPoiMarkers(groupId){
            this.$emit('onClearPoiMarkers', groupId);
        },

        /**
         * Обновить цвет маркеров
         * @param {String} groupId
         * @param {String} color
         */
        onUpdateColorMarkers(groupId, color){
            this.$emit('onUpdateColorMarkers', groupId, color);
        },

        /**
         * Обновить иконки маркеров
         * @param {String} groupId
         * @param {String} src
         */
        onUpdateIconMarkers(groupId, src){
            this.$emit('onUpdateIconMarkers', groupId, src);
        },

        /**
         * Изменить активность маркера
         * @param {Object} marker
         */
        onTogglePoiMarker(marker){
            this.$emit('onTogglePoiMarker', marker);
        },

        onClosePoi() {
            this.activeWidgetName = '';
        },

        onTogglePoiAddress(marker) {
            this.$emit('onTogglePoiAddress', marker);
        }
    },
}
</script>

<style lang="scss" scoped>
    .layout-modules {
        display: flex;
        align-items: flex-start;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
    }
</style>
