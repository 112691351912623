<template>
    <div
        :class="withoutBackdrop ? 'modal_without_backdrop' : 'modal'"
        @click.self="close"
        @scroll="onScrollModal"
    >
        <div
            :style="modalStyle"
            class="modal__container"
            :class="{ 'modal__container--animation': isAnimation }"
        >
            <button class="modal__btn-close icon-tabler-icon-x" @click="close" />
            <div v-if="title" class="modal__body-title">{{ title }}</div>
            <div v-if="description" class="modal__body-description">
                {{ description }}
            </div>
            <slot name="modalBody"></slot>
            <div class="modal__body-actions-wrap">
                <slot name="actionButtons"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseModalContent",
    props: {
        isAnimation: Boolean,
        title: String,
        description: String,
        withoutBackdrop: Boolean,
        modalStyle: Object,
    },
    methods: {
        close() {
            this.$emit("close");
        },
        onScrollModal() {
            this.$emit("scrollModal");
        },
    },
};
</script>

<style lang="scss" scoped>
$color-gray: #afb0b4;
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    z-index: 11;

    &.isAlignCenter {
        align-items: center;
    }

    &__container {
        background-color: white;
        position: relative;
        // width: 520px;
        border-radius: 10px;
        padding: 30px;

        &--animation {
            animation-duration: 0.2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 2;
            animation-name: modalOpenAnimation;
        }
    }

    &__body-title {
        font-size: 20px;
        font-weight: 700;
        color: #000;
        margin-bottom: 5px;
    }

    &__body-description {
        font-size: 12px;
        color: #000;
        margin-bottom: 20px;
    }

    &__btn-close {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 12px;
        color: $color-gray;
        transition: all 0.4s ease-out;

        &:hover {
            color: #000;
        }
    }
}

@keyframes modalOpenAnimation {
    from {
        transform: translateX(-40px);
    }
    50% {
        transform: translateX(40px);
    }
    to {
        transform: translateY(0);
    }
}
.modal_without_backdrop > div {
    max-width: 100%;
}
</style>
