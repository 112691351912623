<template>
    <div class="videoTime">
        <div
            v-for="(priceData, index) of prices"
            :key="index"
            class="videos__item"
        >
            <div class="videos__item-label">
                {{ getFieldValue(priceData, field)}}
            </div>
        </div>
    </div>
</template>

<script>

export default {
	name: "VideoColumn",
	components: {},
    props: {
        prices: {
            type: Array,
            default: () => []
        },
        field: {
            type: String,
            default: ""
        }
    },

    methods: {
        /**
         *
         * @param {Object} priceData
         * @param {String} field
         * @returns {String}
         */
        getFieldValue(priceData, field) {
            return priceData[field] ?? ""
        }
    }
}
</script>

<style lang="scss" scoped>
$height: 30px;
$cellIndent: 5px;
$assistant-color: 			#ADB0BB !default;
$primary-color: 			#F0F3F8 !default;

.videoTime {
    display: flex;
    flex-direction: column;
    width: 50px;
    background: #F5F5F5;
    border-radius: 4px;

    @mixin vertical-header {
        background: $primary-color;
        border-radius: 4px;
    }

    @mixin vertical-header__item {
        height: $height;
        display: flex;
        align-items: center;
        color: $assistant-color;

        &:not(:first-child) {
            margin-top: $cellIndent;
        }
    }

    .videos {
        @include vertical-header;

        &__item {
            @include vertical-header__item;
            // width: 100px;
            width: 50px;
            padding: 0 10px;

            &-label {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

</style>
