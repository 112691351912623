<template>
    <folder
        ref="folder"
        :id="id"
        :title="title"
        :isMinimize="false"
        :width="width"
        :height="height"
        :top="top"
        :left="left"
        :color="color"
        :isRename="isRename"
        class="folder"
        @onRenameFolder="onRenameFolder"
        @click.native="onClickFolder"
        @onResize="onResizeFolder"
    >
        <template v-slot:summary>
            <SummaryData
                :summaryData="summaryData"
                class="folder__summary"
            />
            <AppFooter class="folder__legend" />
        </template>
        <template v-slot:panel>
            <FolderControlPanel
                class="folder__panel"
                :isExport="!!tableRows.length"
                :isRemove="isRemove"
                :isDuplicate="isDuplicate"
                :isBusySides="isBusySides"
                :isEditMassMode.sync="isEditMassMode"
                @remove="onRemoveFolder(id)"
                @exportExcel="onExportFolder(id)"
                @moveToEditMass="onMoveToEditMass"
                @moveToBudget="OnMoveToBudget(id)"
                @onDuplicateKit="OnDuplicate"
                @onShare="onShare"
                @onFullSize="onFullSize"
                @onFiftySize="onFiftySize"
                @onCloseFolder="closeFolder(id)"
                @onRemoveBusySides="onRemoveBusySides"
            />
        </template>
        <template v-slot:content>
            <kit-tab
                :columns="tableColumnsWithTags"
                :modifieredCols="tableColumnsModifered"
                :datesCols="tableColumnsDates"
                :tableData="tableRows"
                :folderId="id"
                :rowsCheckboxes="tableRowsPricesSelected"
                :style="{'height': '100%'}"
                :height="tableHeight"
                :is-virtual-scroll="false"
                :isProgressBarLoading="isProgressBarLoading"
                :tagList="tagList"
                :canCheck="isEditMassMode"
                :isStickyCheckbox="true"
                :rowsSelected="rowsIdsSelected"
                @onCheckSubCheckbox="onCheckSubCheckbox"
                @filter="onFilter"
                @sort="onSort"
                @onClickCell="onClickCell"
                @onAddPricesRow="onAddPriceTable"
                @onRemovePricesRow="onRemovePriceTable"
                @lazyload="onLazyloadTableData"
                @selectTags="onSelectTags"
                @createTag="onCreateTag"
                @deleteTags="onDeleteTags"
                @onCheckRow="onCheckRow"
                @onCheckRowsAll="onCheckRowsAll"
            />
            <modal-share
                v-if="shareIsModal"
                :shareLink="shareLink"
                :poiItems="sharedPoiItems"
                @onCloseModal="onCloseShareModal"
                @onRefreshLink="onRefreshLink"
            />

            <ReconciliationModalMassEdit
                v-if="isEditMassModal"
                :tableRowsToEdit="editMassData"
                @onCloseModal="onCloseEditMassModal"
                @createValueToEdit="onCreateValueToEdit"
                @removeValuesToEdit="onRemoveValuesToEdit"
                @applyValuesToEdit="onApplyValuesToEdit"
                @onSaveChanges="onSaveChangesEditMass"
            />
        </template>
    </folder>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import ServiceApi from '@/services/ServiceApi.js';
import ServiceShare from '@/services/ServicesAuth/ServiceShare';
import ServiceKit from '../../services/ServicesKit/ServiceKit.js';
import Folder from '@/components/Folder/Folder';
import MixinTableFound from '@/mixins/MixinsPageBayuerdesk/MixinTableFound';
import MixinMapFound from '@/mixins/MixinsPageBayuerdesk/MixinMapFound';
import KitTab from '@/components/HandbookComponents/KitTab';
import SummaryData from '@/components/Folder/SummaryData'
import FolderControlPanel from '@/components/Folder/FolderControlPanel';
import ReconciliationModalMassEdit from '@/components/Reconciliation/ReconciliationModals/ReconciliationModalMassEdit.vue';
import ModalShare from '@/components/Folder/ModalShare';
import MixinSummary from "@/mixins/MixinSummary";
import AppFooter from '../../components/ConstructionCard/ConstructionCardFree/Components/Footer.vue';
import ServiceKitSideTag from '@/services/ServicesKit/ServiceKitSideTag.js';

export default {
    name: 'LayoutFolder',
    components: {
        Folder,
        KitTab,
        SummaryData,
        FolderControlPanel,
        ReconciliationModalMassEdit,
        ModalShare,
        AppFooter
    },
    mixins: [
        MixinTableFound,
        MixinMapFound,
        MixinSummary
    ],
    /**
     * Входные данные
     * @property {String} id - идентификатор папки
     * @property {String} title - название папки
     * @property {String} folderType - тип папки
     * @property {String} width - позиция top папки
     * @property {String} height - позиция top папки
     * @property {String} top - позиция top папки
     * @property {String} left - позиция left папки
     * @property {String} color - цвет папки
     * @property {Boolean} isRename - возможность переименования папки
     * @property {Boolean} isCurrent - активность набора папки
     */
    props: {
        id: {
            type: String,
            default: '-1'
        },
        title: {
            type: String,
            default: ''
        },
        folderType: {
            type: String,
            default: 'found',
            validator: folderType => ['found', 'custom'].includes(folderType)
        },
        width: {
			type: String,
			default: '500px'
		},
		height: {
			type: String,
			default: '300px'
		},
        top: {
            type: String,
            default: '10px'
        },
        left: {
            type: String,
            default: '10px'
        },
        color: {
            type: String,
            default: ''
        },
        isRename: {
            type: Boolean,
            default: false
        },
        isCurrent: {
            type: Boolean,
            default: false
        },
        isRemove: {
            type: Boolean,
            default: true
        },
        isDuplicate: {
            type: Boolean,
            default: true
        },
        currentIconType: {
            type: String,
            default: 'default'
        },
        legendData: {
            type: Object,
            default: () => ({})
        },
        tagList: {
            type: Array,
            default: () => ([])
        }
    },
    /**
     * Данные компонента
     * @property {Number} tableResizedHeight - Пересчет высоты таблицы при resize
     * @property {String} shareLink - Ссылка для шары
     * @property {Boolean} shareIsModal - Условие ренедера модального окна с ссылкой для шары
     * @property {Boolean} isProgressBarLoading - preloader
     */
    data: () => ({
        tableResizedHeight: 0,
        shareLink: '',
        shareIsModal: false,
        isProgressBarLoading: false,
        kitItemsData: [],
        tableTotalPage: 0,
        isBusySides: false,
        sharedPoiItems: [],
        updatePriceTimerId: null,
        tagListByKitRequestData: {
            page: 1,
            perPage: 40,
            pageCount: 1,
            searchQuery: '',
            kitId: ''
        },
        tagListByKit: [],
        isEditMassMode: false,
        isEditMassModal: false,
        rowsIdsSelected: [],
        isRowsIdsSelectedAll: false,
        editMassData: {
            editableFields: [
                    {
                        prop: 'linkTag',
                        actionApi: 'link',
                        label: 'Привязать теги',
                        type: 'selectMultiple',
                        options: [],
                        value: [],
                        page: 1,
                        totalCount: 0
                    },
                    {
                        prop: 'unlinkTag',
                        actionApi: 'unlink',
                        label: 'Отвязать теги',
                        type: 'selectMultiple',
                        options: [],
                        value: [],
                        page: 1,
                        totalCount: 0
                    }
                ]
        }
    }),

    mounted() {
        /**
         * При монтировании проверяем является ли набор текущим выбранным,
         * при необходимости делаем запрос на данные
         * и устанавливаем в стор выбранные цены
         */
        this.getTableRows()
        // this.postSummaryData();

        if (this.isCurrent)
            this.getKitItemsData();

        this.setEditMassData({ fieldProp: 'linkTag', fieldKey: 'options', fieldValue: [...this.tagList] });
        this.setEditMassData({ fieldProp: 'unlinkTag', fieldKey: 'options', fieldValue: [...this.tagList] });
    },

    watch: {
        /**
         * Следим является ли набор текущим выбранным,
         * при необходимости делаем запрос на данные
         * и устанавливаем в стор выбранные цены
         */
        isCurrent() {
            this.getTableRows()

            if (this.isCurrent)
                this.getKitItemsData();
        },

        tagList() {
            this.setEditMassData({ fieldProp: 'linkTag', fieldKey: 'options', fieldValue: [...this.tagList] });
            this.setEditMassData({ fieldProp: 'unlinkTag', fieldKey: 'options', fieldValue: [...this.tagList] });
        }
    },

    computed: {
        ...mapState({
            // filterCityId: (state) => {
            //     return state.filter.filterSelectedData.city_id[0] ?? null;
            // },
            filterLocationData: (state) => {
                return {
                    country_id: state?.filter?.filterSelectedData?.country_id ?? [],
                    region_id: state?.filter?.filterSelectedData?.region_id ?? [],
                    location_id: state?.filter?.filterSelectedData?.location_id ?? [],
                };
            }
        }),
        tableColumnsWithTags() {
            const tableColumnTagsIndex = this.tableColumns.findIndex(column => String(column?.prop ?? '') === 'sideTags');
            const tableColumnTags = {
                ...this.tableColumns[tableColumnTagsIndex], 
                searchItems: [...this.tagListByKit]
            };
            let tableColumnsWithTags = [...this.tableColumns];
            
            if (tableColumnTagsIndex !== -1)
                tableColumnsWithTags.splice(tableColumnTagsIndex, 1, tableColumnTags);

            return tableColumnsWithTags;
        },

        tableRowsAll() {
            const tableRowsIds = this.tableRows.map(tableRow => String(tableRow?.id ?? ''));
            const kitItemsDataAdded = this.kitItemsData.filter(kitItemData => !tableRowsIds.includes(String(kitItemData?.id ?? '')));
            const tableRowsAll = [...this.tableRows, ...kitItemsDataAdded];

            return tableRowsAll;
        },
        /**
         * Высота таблицы
         * @returns {Number}
         */
        tableHeight() {
            if (this.tableResizedHeight !== undefined && this.tableResizedHeight > 0) {
                return this.tableResizedHeight;
            }
            const heightFolder = this.folderType === 'found' ? Math.ceil(window.innerHeight * 0.65) : 300;
            const heightFolderHeader = 39;
            const heightFolderSummary = 44;

            return heightFolder - (heightFolderHeader + heightFolderSummary);
        },
        /**
         * Тело запроса для таблицы
         * @returns {Object}
         */
        tableRequestBody() {
            const tableRequestBody = {
                set_id: this.id,
                sort: this.tableSort,
                filter: this.tableFilter,
                pagination: {
                    per_page: parseInt(this.tableFoundPerPage),
                    page: parseInt(this.tableFoundPageNumber)
                },
            };
            return tableRequestBody;
        },
        /**
         * Выбранные цены набора
         * @returns {Object}
         */
        tableRowsPricesSelected() {
            let tableRowsPricesSelected = {};
            // this.tableRows.forEach(row => {
            //     const {id = '-1', prices = []} = row;
            //     tableRowsPricesSelected[id] = prices.map(price => {
            //         const {date = ''} = price;
            //         return date;
            //     })
            // });

            this.tableRowsAll.forEach(row => {
                const { id = '-1', prices = [] } = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {date = ''} = price;
                    return date;
                });
            });

            return tableRowsPricesSelected;
        },
        /**
         * Выбранные id цен набора
         * @returns {Object}
         */
        tableRowsPricesIdsSelected() {
            let tableRowsPricesSelected = {};
            // this.tableRows.forEach(row => {
            //     const {id = '-1', prices = []} = row;
            //     tableRowsPricesSelected[id] = prices.map(price => {
            //         const {id = '-1'} = price;
            //         return id;
            //     })
            // });

            this.tableRowsAll.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {id = '-1'} = price;
                    return id;
                })
            });

            return tableRowsPricesSelected;
        },
        /**
         * Выбранные id цен набора
         * @returns {Object}
         */
        tableRowsPrices() {
            let tableRowsPricesSelected = {};
            // this.tableRows.forEach(row => {
            //     const {id = '-1', prices = []} = row;
            //     tableRowsPricesSelected[id] = prices;
            // });

            this.tableRowsAll.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices;
            });

            return tableRowsPricesSelected;
        },
        /**
         * Выбранные id цен набора
         * @returns {Object}
         */
        tableCatalogPrices() {
            let tableRowsPricesSelected = {};
            // this.tableRows.forEach(row => {
            //     const {catalog_id:catalogId = '-1', prices = []} = row;
            //     tableRowsPricesSelected[catalogId] = prices;
            // });

            this.tableRowsAll.forEach(row => {
                const {catalog_id:catalogId = '-1', prices = []} = row;
                tableRowsPricesSelected[catalogId] = prices;
            });

            return tableRowsPricesSelected;
        },
        /**
         * Выбранные id каталога набора
         * @returns {Object}
         */
        tableRowsCatalogIdsSelected() {
            let tableRowsSelected = {};
            // this.tableRows.forEach(row => {
            //     const {id = '-1', catalog_id:catalogId = '-1'} = row;
            //     tableRowsSelected[catalogId] = id;
            // });

            this.tableRowsAll.forEach(row => {
                const {id = '-1', catalog_id:catalogId = '-1'} = row;
                tableRowsSelected[catalogId] = id;
            });

            return tableRowsSelected;
        },
        /**
         * Выбранные id цен набора
         * @returns {Object}
         */
        tableRowsCatalogIdsPricesSelected() {
            let tableRowsSelected = {};
            // this.tableRows.forEach(row => {
            //     const {catalog_id: catalogId = '-1', prices = []} = row;
            //     tableRowsSelected[catalogId] = prices.map(price => {
            //         const {id = '-1'} = price;
            //         return id;
            //     });
            // })

            this.tableRowsAll.forEach(row => {
                const {catalog_id: catalogId = '-1', prices = []} = row;
                tableRowsSelected[catalogId] = prices.map(price => {
                    const {id = '-1'} = price;
                    return id;
                });
            });

            return tableRowsSelected;
        }
    },
    methods: {
        /** Проксируем мутации из стора */
        ...mapMutations('kit', ['setTableCustomPricesSelected', 'setTableCustomRows']),
        /** Получаем методы для папок */
        ...mapMutations('folders', ['toggleResizeFolder', 'closeFolder']),
        onSaveChangesEditMass() {
            const fieldIndex = this.editMassData.editableFields.findIndex(field => field.value?.length);
            const field = this.editMassData.editableFields[fieldIndex];
            const action = String(field?.actionApi ?? '');
            const tagsIds = field?.value ?? [];
            const sidesIds = this.rowsIdsSelected.map(rowId => parseInt(rowId));

            this.selectTags({ action, tagsIds, sidesIds, isSelectedAll: this.isRowsIdsSelectedAll });
        },
        setEditMassData({ fieldProp = '', fieldKey = '', fieldValue = null }) {
            const fieldIndex = this.editMassData.editableFields.findIndex(field => String(field.prop) === String(fieldProp));

            if (fieldIndex !== -1)
                this.editMassData.editableFields[fieldIndex][fieldKey] = fieldValue;
        },
        onCreateValueToEdit(optionToEditId = '', valueToEdit = {}) {
            if (optionToEditId === 'linkTag' || optionToEditId === 'unlinkTag')
                this.onCreateTag(valueToEdit);
        },
        onRemoveValuesToEdit(optionToEditId = '', valuesIdsToEdit = []) {
            if (optionToEditId === 'linkTag' || optionToEditId === 'unlinkTag')
                this.onDeleteTags(valuesIdsToEdit);
        },
        onApplyValuesToEdit(optionToEditId = '', valuesIdsToEdit = []) {
            this.setEditMassData({ fieldProp: 'linkTag', fieldKey: 'value', fieldValue: [] });
            this.setEditMassData({ fieldProp: 'unlinkTag', fieldKey: 'value', fieldValue: [] });

            this.setEditMassData({ fieldProp: optionToEditId, fieldKey: 'value', fieldValue: [...valuesIdsToEdit] });
        },
        onCheckRow(checkData = {}) {
            this.rowsIdsSelected = checkData?.rowsIdsSelected ? [...checkData.rowsIdsSelected] : [];
        },

        onCheckRowsAll(isSelected = false) {
            this.isRowsIdsSelectedAll = Boolean(isSelected);
            this.rowsIdsSelected = isSelected ? this.tableRowsAll.map(row => String(row?.id ?? '')) : [];
        },
        onCloseEditMassModal() {
            this.isEditMassModal = false;
        },
        async getTagListByKit(searchQuery = '') {
            this.tagListByKitRequestData.searchQuery = String(searchQuery);
            this.tagListByKitRequestData.kitId = String(this.id);

            try {
                const { tagList = [], pageCount = 1 } = await ServiceKitSideTag.getTagList(this.tagListByKitRequestData);
                this.tagListByKit = [...tagList];
                this.tagListByKitRequestData.pageCount = parseInt(pageCount);
            } catch (error) {
                console.log(error);
                // this.$notify.dNotify({
                //     type: 'error',
                //     title: 'Ошибка получения списка тегов',
                //     description: error,
                //     duration: Infinity,
                //     position: 'bottom-right'
                // });
            }
        },
        onSelectTags({ tagsIdsSelected = [], tagsIdsLinked = [], sideId = -1 }) {
            const tagsIdsToLink = tagsIdsSelected.filter(tagId => !tagsIdsLinked.includes(parseInt(tagId)));
            const tagsIdsToUnlink = tagsIdsLinked.filter(tagId => !tagsIdsSelected.includes(parseInt(tagId)));
            
            if (tagsIdsToLink.length)
                this.selectTags({ action: 'link', tagsIds: tagsIdsToLink, sidesIds: [sideId], isSelectedAll: false });
            if (tagsIdsToUnlink.length)
                this.selectTags({ action: 'unlink', tagsIds: tagsIdsToUnlink, sidesIds: [sideId], isSelectedAll: false });
        },
        selectTags({ action = '', tagsIds = [], sidesIds = [], isSelectedAll = false }) {
            this.toggleLinkTags({ action, tagsIds, sidesIds, isSelectedAll });

            const tagList = this.tagList.filter(tag => tagsIds.includes(parseInt(tag?.id ?? -1)));
            let tableRowsBuffer = [...this.tableRows];
            tableRowsBuffer.map(row => {
                if (sidesIds.includes(parseInt(row?.id ?? -1))) {
                    if (action === 'link') {
                        let sideTags = row.sideTags.filter(tag => !tagsIds.includes(parseInt(tag?.id ?? -1)));
                        row.sideTags = [...sideTags, ...tagList];
                    } else if (action === 'unlink')
                        row.sideTags = row.sideTags.filter(tag => !tagsIds.includes(parseInt(tag?.id ?? -1)));
                    
                    return row;
                } else
                    return row;
            });

            this.tableRows = [...tableRowsBuffer];
        },
        onCreateTag(tag = {}, sideId = -1) {
            this.$emit('createTag', tag, sideId);
        },
        onDeleteTags(tagsIds = []) {
            this.tableRows = this.tableRows.map(row => ({
                ...row,
                sideTags: row?.sideTags?.filter(tag => !tagsIds.includes(parseInt(tag?.id ?? -1)))
            }));
            
            this.$emit('deleteTags', tagsIds);
        },
        async toggleLinkTags({ action = 'link', tagsIds = [], sidesIds = [], isSelectedAll = false }) {
            try {
                await ServiceKitSideTag.toggleLinkTags({ action, tagsIds, sidesIds, kitId: parseInt(this.id), isSelectedAll });
            } catch (error) {
                this.$notify.dNotify({
                    type: 'error',
                    title: 'Ошибка прикрепления тегов',
                    description: error,
                    duration: Infinity,
                    position: 'bottom-right'
                });
            }
        },
        async getKitItemsData() {
            this.kitItemsData = [];
            this.kitItemsData = await ServiceKit.getKitItemsData(this.id);
        },

        async clearBusyPrices() {
            await ServiceKit.clearBusyPrices(this.id, () => {
                this.tableRows = [];
                this.getTableRows()
                this.getKitItemsData();
            });
        },

        onLazyloadTableData() {
            if (this.tableTotalPage >= this.tableFoundPageNumber) {
                this.tableFoundPageNumber += 1;
                this.postTableDataCustomKit((rows) => {
                    this.afterPostTableData(rows, 'allPrices');
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    // this.setTableCustomRows(this.tableRows);
                    this.setTableCustomRows(this.tableRowsAll);
                });
            }
        },
        getTableRows() {
            if (this.isCurrent) {
                this.tableFoundPageNumber = 1;

                if(this.tableRows.length === 0) {
                    this.postTableDataCustomKit((rows) => {
                        this.afterPostTableData(rows, 'allPrices');
                        this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                        // this.setTableCustomRows(this.tableRows);
                        this.setTableCustomRows(this.tableRowsAll);
                    });
                }
                else {
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    // this.setTableCustomRows(this.tableRows);
                    this.setTableCustomRows(this.tableRowsAll);
                }
            }
        },
        /**
         * Запрос на получение табличных данных наора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableDataCustomKit(actionAfter = () => {}) {
            this.isProgressBarLoading = true;
            try {
                const res = await ServiceApi.post('free', '/map/set-table', this.tableRequestBody);
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                const {rows = [], info: kitItem = {}} = dataCustom;
                this.tableTotalPage = Math.ceil(parseInt(dataCustom?.info?.count ?? 0) / this.tableFoundPerPage);
                // if (rows.length > 0)
                    actionAfter(rows);
                this.isProgressBarLoading = false;

                const {count_busy: countBusy = 0} = kitItem;
                this.isBusySides = countBusy > 0;

                this.$emit('updateKitItem', kitItem);
            }
            catch(error) {
                console.log(error);
                this.isProgressBarLoading = false;
            }
        },
        async postMarkersDataCustom(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-markers', postData);
                const {data = {}} = res;
                const {data: markers = []} = data;
                actionAfter(markers);
            }
            catch(error) {
                console.log(error);
            }
        },

        /**
         * Получение кластеров и маркеров набора
         * @param {Object} postData
         * @param {Function} actionAfter
         * @returns {Promise<void>}
         */
        async postMarkersAndClustersDataCustom(postData = {}, actionAfter = () => {}) {
            try {
                const res = await ServiceApi.post('free', '/map/set-clusters', postData);
                const {data: responseData = {}} = res;
                const {data = []} = responseData;
                actionAfter(data);
            }
            catch(error) {
                console.log(error);
            }
        },

        async postSummaryData() {
            try {
                const response = await ServiceApi.post('free', '/map/set-summary', {set_id: this.id});
                if(typeof response !== 'undefined'){
                    const result = response.data.data
                    this.summaryData = this.AdapterSummaryData(result)
                }

            } catch(error) {
                console.log(error);
            }
        },
        /**
         * Обновить данные
         * @param {Function} actionAfter - событие сработающее после обновления
         */
        updateDataCustomKit(actionAfter = () => {}) {
            this.tableFoundPageNumber = 1;
            this.postTableDataCustomKit((rows) => {
                this.afterUpdateDataCustomKit(rows, true, actionAfter);
            });
        },
        /**
         * Обновить данные
         * @param {Array} rows - строки
         * @param {Boolean} isRerender - флаг перерисовки
         * @param {Function} actionAfter - событие сработающее после обновления
         */
        afterUpdateDataCustomKit(rows, isRerender = false, actionAfter = () => {}){
            this.afterPostTableData(rows, 'allPrices', isRerender);
            this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
            // this.setTableCustomRows(this.tableRows);
            this.setTableCustomRows(this.tableRowsAll);
            this.$nextTick(() => {
                actionAfter(this.tableRowsPricesIdsSelected, this.tableRowsCatalogIdsPricesSelected);
            });
        },
        /**
         * Получить маркеры
         * @deprecated
         */
        getMarkers() {
            if (this.markers.length === 0) {
                this.updateMarkers();
            }
            else
                this.$emit('drawMarkers', this.id);
        },

        /**
         * Получить маркеры и кластеры
         * @param {Array} bound
         * @param {Number} viewZoom
         * @param {Boolean} isGrouping
         * @param {Number} poiRadius
         * @param {Array} poiPoints
         * @param {String} poiRadiusDirectionType
         * @param {Function} actionAfter
         */
        getMarkersAndClusters(
            bound, viewZoom, isGrouping,
            poiRadius, poiPoints, poiRadiusDirectionType,
            actionAfter = () => {}
        ) {
            this.$emit('beforeGetMarkers');
            const {latMin, latMax, lngMin, lngMax} = bound;
            const postData = {
                set_id: this.id,
                bound: [
                    [latMin, lngMin],
                    [latMax, lngMax]
                ],
                radius: poiRadius,
                points: poiPoints,
                side_turn_poi: poiRadiusDirectionType,
                view_zoom: viewZoom,
                is_grouping: isGrouping
            };

            this.postMarkersAndClustersDataCustom(postData, (data) => {
                const {
                    markers,
                    clusters,
                    bound: loadedBound = {}
                } = data;
                this.markers = this.adapterMarkers(markers, this.color, this.id, true, this.currentIconType);
                this.clusters = clusters;

                actionAfter(loadedBound);

                this.$emit('afterGetMarkers', this.id, this.markers, this.clusters);
            });
        },

        /**
         * Обновить маркеры
         * @deprecated
         */
        updateMarkers() {
            this.$emit('beforeGetMarkers');
            const postData = {set_id: this.id};
            this.postMarkersDataCustom(postData, (markers) => {
                this.markers = this.adapterMarkers(markers, this.color, this.id, true, this.currentIconType);
                this.$emit('afterGetMarkers', this.id, this.markers);
            });
        },
        /**
         * Событие переименования папки
         * @param {String} id - идентификатор папки
         * @param {String} newName - новое имя папки
         */
        onRenameFolder(id = '-1', newName = '') {
            this.$emit('onRenameFolder', String(id), newName);
        },
        onRemoveFolder(id = '-1') {
            // this.tableRows = [];
            this.$emit('removeFolder', String(id));
        },
        onExportFolder(id = '-1') {
            this.$emit('exportFolder', String(id));
        },
        onMoveToEditMass() {
            this.isEditMassModal = true;
        },
        OnMoveToBudget(id = '-1') {
            this.$emit('moveToBudget', String(id));
        },

        onRemoveBusySides(){
            this.clearBusyPrices();
        },

        OnDuplicate() {
            this.$emit('onDuplicate', String(this.id));
        },
        /**
         * Событие клика по папке
         */
        onClickFolder() {
            this.$emit('onClickFolder', this.id, this.folderType);
        },
        /**
         * Событие выбора цены в наборе
         * @property {Object} checkData - данные выбора
         */
        onCheckSubCheckbox(checkData = {}) {
            this.$emit('onCheckSubCheckbox', checkData);
        },
        /**
         * Событие фильтрации по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        onFilter(dataFilter = {}) {
            this.tableFoundPageNumber = 1;
            this.beforeFilterTable(dataFilter);
            this.postTableDataCustomKit((rows) => {
                this.afterPostTableData(rows, 'allPrices', true);
            });

            if (Object.keys(dataFilter).includes('sideTags'))
                this.getTagListByKit(dataFilter.sideTags);
        },
        /**
         * Событие сортировки
         * @param {Object} dataSort - данные сортировки
         */
        onSort(dataSort = {}) {
            this.tableFoundPageNumber = 1;
            this.beforeSortTable(dataSort);
            this.postTableDataCustomKit((rows) => {
                this.afterPostTableData(rows, 'allPrices', true);
            });
        },
        updatePrice() {
            if (this.updatePriceTimerId) {
                clearTimeout(this.updatePriceTimerId);
                this.updatePriceTimerId = null;
            }

            this.updatePriceTimerId = setTimeout(() => {
                if (this.isCurrent) {
                    this.updateDataCustomKit();
                    this.getKitItemsData();
                }
            }, 2000);
        },
        /**
         * Добавить цены в таблицу
         * @property {Array} rows - строки
         */
        // eslint-disable-next-line no-unused-vars
        addPriceTable(rows = []) {
            this.updatePrice();
            // if (this.isCurrent) {
            //     this.updateDataCustomKit();
            //     this.getKitItemsData();

            //     // let isUpdateRows = false;
            //     // rows.forEach(row => {
            //     //     // const currentRowsIds = this.tableRows.map(row => String(row.id));
            //     //     const currentRowsIds = this.tableRowsAll.map(row => String(row.id));
            //     //     const {id = '-1'} = row;
            //     //     if (!currentRowsIds.includes(id)) {
            //     //         // this.afterPostTableData(rows, 'allPrices')
            //     //         isUpdateRows = true;
            //     //     }
            //     //     else {
            //     //         const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(id));
            //     //         const rowsAdapted = this.adapterRows([row], 'allPrices');
            //     //         if (index !== -1) {
            //     //             this.tableRows.splice(index, 1, rowsAdapted[0]);
            //     //             const columnsDatesAdapted = this.adapterColumnsDates(this.tableRows, 'allPrices');
            //     //             this.collectColumns(columnsDatesAdapted);
            //     //         }
            //     //     }
            //     // });

            //     // if (isUpdateRows)
            //     //     this.tableRows = [];
            //     //     this.getTableRows();

            //     // this.getKitItemsData();

            //     // this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
            //     // // this.setTableCustomRows(this.tableRows);
            //     // this.setTableCustomRows(this.tableRowsAll);
            // }
        },
        /**
         * Добавить все цены в строке
         * @property {Object} checkData - данные выбора
         */
        onAddPriceTable(checkData) {
            this.$emit('onAddPriceTable', checkData);
        },
        /**
         * Удалить все цены в строке
         * @property {Object} checkData - данные выбора
         */
        onRemovePriceTable(checkData) {
            this.$emit('onRemovePriceTable', checkData);
        },
        /**
         * Удалить цены из таблицу
         * @property {Object} checkData - данные выбора
         */
        // eslint-disable-next-line no-unused-vars
        removePriceTable(rows = []) {
            this.updatePrice();
            // if (this.isCurrent) {
            //     this.updateDataCustomKit();
            //     this.getKitItemsData();

            //     // rows.forEach(row => {
            //     //     const {id = '-1'} = row;
            //     //     const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(id));
            //     //     const rowsAdapted = this.adapterRows([row], 'allPrices');
            //     //     if (index !== -1) {
            //     //         this.tableRows.splice(index, 1, rowsAdapted[0]);
            //     //         const columnsDatesAdapted = this.adapterColumnsDates(this.tableRows, 'allPrices');
            //     //         this.collectColumns(columnsDatesAdapted);
            //     //     }
            //     // });

            //     // this.getKitItemsData();

            //     // this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
            //     // // this.setTableCustomRows(this.tableRows);
            //     // this.setTableCustomRows(this.tableRowsAll);
            // }
        },
        /**
         * Очистить таблицу
         */
        clearRowTable() {
            if (this.isCurrent) {
                this.tableRows = [];
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
        },
        /**
         * Удалить строку из таблицы
         * @property {String} rowId - идентификатор набора
         */
        // eslint-disable-next-line no-unused-vars
        removeRowTable(rowId = '-1') {
            this.updatePrice();
            // if (this.isCurrent) {
            //     this.updateDataCustomKit();
            //     this.getKitItemsData();

            //     // const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(rowId));
            //     // if (index !== -1) {
            //     //     this.tableRows.splice(index, 1);
            //     //     const columnsDatesAdapted = this.adapterColumnsDates(this.tableRows, 'allPrices');
            //     //     this.collectColumns(columnsDatesAdapted);
            //     // }

            //     // this.getKitItemsData();

            //     // this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
            //     // // this.setTableCustomRows(this.tableRows);
            //     // this.setTableCustomRows(this.tableRowsAll);
            // }
        },
        onClickCell(data){
            this.$emit('onClickCell', data);
        },
        /**
         * Высчитать высоту таблицы при ресайзе окна folder
         * @property {Number} heightTable - Высота таблицы
         */
        onResizeFolder() {
            const heightFolder = this.$refs.folder.$el.offsetHeight;
            const heightFolderHeader = 39;
            const heightFolderSummary = 44;

            this.tableResizedHeight = heightFolder - (heightFolderHeader + heightFolderSummary);
        },

        /**
         * Получить ссылку для шары
         */
        onShare() {
            let poiGroups = {};
            this.$parent.$refs.mapView.updateMarkers((marker) => {
                const { extraData = {} } = marker;
                const { type = 'default', groupId = null, id = null, isMoiPoi = false} = extraData;
                if (type !== 'poi' || groupId === null || id === null) {
                    return;
                }
                if(!Object.keys(poiGroups).includes(groupId)){
                    poiGroups[groupId] = {
                        group_id: groupId,
                        type: isMoiPoi ? 2 : 1,
                        poi_ids: []
                    };
                }
                poiGroups[groupId].poi_ids.push(id);
            })
            const {custom: {groupSelected = 'default'} = {}} = this.legendData;

            const requestBody = {
                set_id: this.id,
                params: {
                    poi: {
                        // city_id: this.filterCityId,
                        groups: Object.values(poiGroups),
                        ...this.filterLocationData
                    },
                    legend: groupSelected
                }
            };

            ServiceShare.getLink(requestBody, (link, sharedPoiItems) => {
                this.shareLink = link;
                this.shareIsModal = true;
                this.sharedPoiItems = sharedPoiItems
            });
        },

        /**
         * Удалить ссылку для шары
         */
        onRefreshLink() {
            ServiceShare.removeLink(this.id, () => {
                this.onShare();
            });
        },

        /**
         * Закрыть модальное окно шары
         */
        onCloseShareModal() {
            this.shareIsModal = false;
        },

        /**
         * Расширить на все окно
         */
        onFullSize() {
            this.toggleResizeFolder({ id: this.id, resize: 'full' });
        },
        /**
         * Расширить на половину ширины окна
         */
        onFiftySize() {
            this.toggleResizeFolder({ id: this.id, resize: 'fifty' });
        },
    }
}
</script>

<style lang="scss" scoped>
.folder {
    &__panel {
        display: flex;
        justify-content: flex-end;
    }

    // &__summary {
    //     margin-top: 12px;
    //     margin-bottom: 15px;
    // }

    &__legend {
        margin-top: 12px;
    }
}
</style>
