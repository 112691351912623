import SchemePageBayuerdesk from '@/schemes/SchemePageBayuerdesk';
const {kitPanelItems, kitColors} = SchemePageBayuerdesk;
export default {
    /**
     * Данные компонента
     * @property {Array} kitPanelItems - элементы панели наборов
     * @property {Array} kitColors - палитра цветов для наборов
     * @property {String} kitCustomIdSelected - идентификатор выделенного набора
     */
    data: () => ({
        kitPanelItems: kitPanelItems.slice(),
        kitColors: kitColors,
        kitCustomIdSelected: '-1',
        kitsMarkersActive: []
    }),
    computed: {
        /**
         * Найденно
         * @returns {Object}
         */
        kitFound() {
            const kitFound = this.kitPanelItems.find(kitItem => kitItem.type === 'found');
            return kitFound;
        },
        /**
         * Идентификатор набора найденное
         * @returns {String}
         */
        kitFoundId() {
            const {id = '-1'} = this.kitFound;
            return String(id);
        },
        /**
         * Пользовательские наборы
         * @returns {Array}
         */
        kitCustoms() {
            // let kits = Object.assign([], this.kitPanelItems);
            // const kitFoundIndex = this.kitPanelItems.findIndex(kitItem => kitItem.type === 'found');
            // if(kitFoundIndex !== -1){
            //     kits.splice(kitFoundIndex, 1);
            // }
            // return kits;
            
            return this.kitPanelItems.filter(kitItem => kitItem.type === 'custom');
        }
    },
    methods: {
        /**
         * Выделить набор
         * @param {String} id - идентификатор элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        selectKit(id = '-1', type = '') {
            if (type === 'custom') {
                if (this.kitCustomIdSelected !== String(id)) {
                    this.kitCustomIdSelected = String(id);
                }
            }
        },
        /**
         * Создать набор
         * @returns {Object}
         */
        createKit() {
            const name = `Новый набор ${this.kitPanelItems.length}`;
            const randIndex = Math.floor(Math.random() * (14));
            const color = this.kitColors[randIndex];
            const newKit = {name, color};
            return newKit;
        },
        /**
         * Установить свойство для объекта набора
         * @param {String} kitId - идентификатор набора
         * @param {String} propName - название свойства
         * @param {String|Number} propValue - значение сдля свойства
         */
        setKitProp(kitId = '-1', propName = '', propValue = '') {
            const kitFoundIndex = this.kitPanelItems.findIndex(kit => String(kit.id) === String(kitId));
            if (kitFoundIndex !== -1)
                this.kitPanelItems.splice(kitFoundIndex, 1, {...this.kitPanelItems[kitFoundIndex], [propName]: propValue});
        },
        /**
         * Установить описание для найдено
         * @param {Number} count - колличество сторон
         */
        setDescriptionKitFound(count = 0) {
            this.setKitProp(this.kitFoundId, 'description', count);
        },
        /**
         * Адаптер для элементов панели наборов
         * @param {Array} kits - наборы
         * @returns {Array}
         */
        adapterKitPanel(kits = []) {
            const kitsAdapted = kits.map(kit => {
                const {id = '-1', created_at: timestamp = -1, name: title = '', count = 0, price = null, color = ''} = kit;
                const kitAdapted = {
                    id: String(id),
                    timestamp: parseInt(timestamp),
                    title: String(title),
                    type: 'custom',
                    description: `${count} / ${price === null ? 0 : price}`,
                    color
                };
                return kitAdapted;
            });
            return kitsAdapted;
        },
        /**
         * Обновить набор
         * @param {Object} kit - набор
         */
        updateKit(kit = {}) {
            const {id: kitId = '-1'} = kit;
            const adaptedKits = this.adapterKitPanel([kit]);
            const index = this.kitPanelItems.findIndex(kit => String(kit.id) === String(kitId));
            if (index !== -1) {
                this.kitPanelItems.splice(index, 1, adaptedKits[0]);
            }
        },
        /**
         * Событие для обработки результата запроса получения списка наборов
         * @param {Array} kits - наборы
         */
        afterPostKitsList(kits = []) {
            const adaptedKits = this.adapterKitPanel(kits);
            // let kitMap = this.kitPanelItems.map(item => item.id);
            // adaptedKits.forEach(item => {
            //     let indexKit = kitMap.indexOf(item.id);
            //     if(indexKit === -1){
            //         this.kitPanelItems.push(item);
            //     }else {
            //         this.kitPanelItems[indexKit] = item;
            //     }
            // });
            adaptedKits.reverse();
            this.kitPanelItems.push(...adaptedKits);
            if (adaptedKits.length !== 0) {
                let {id = '-1'} = adaptedKits[0];
                this.kitCustomIdSelected = String(id);
            }
        },
        /**
         * Событие для обработки результата запроса создания набора
         * @param {Object} kit - набор
         * @param {Boolean} isSetSelected - установить выбранный набор
         */
        afterPostCreateKit(kit = {}, isSetSelected = true) {
            const newKits = this.adapterKitPanel([kit]);
            const newKit = newKits[0];
            const {id = '-1'} = newKit;
            this.kitPanelItems.push(newKit);
            if(isSetSelected){
                this.kitCustomIdSelected = String(id);
            }
        },
        afterPostRemoveKit(id) {
            this.kitPanelItems = this.kitPanelItems.filter(item => item.id != id)
        },
        afterPostExportKit(link){
            window.open(link);
        },
        removeFoundKit() {
            this.kitPanelItems = this.kitPanelItems.filter(item => item.id != this.kitFound.id);
        },
        afterPostRemoveKitAndSetActive(id) {
            this.afterPostRemoveKit(id);
            const customKits = this.kitPanelItems.filter(item => item.type == 'custom');
            if (customKits.length) {
                let {id = '-1'} = customKits[0];
                this.kitCustomIdSelected = String(id);
            }
        },
        /**
         * Событие для обработки результата запроса переименования набора
         * @param {String} id - идентификатор набора
         * @param {String} newName - новое имя
         */
        afterPostRenameKit(id = '-1', newName = '') {
            this.setKitProp(String(id), 'title', newName);
        },
        /**
         * Получить цвет набора
         * @param {String} kitId - идентификатор набора
         * @returns {String}
         */
        getColorKit(kitId = -1) {
            const kit = this.kitPanelItems.find(kit => String(kit.id) === String(kitId)) || {};
            const {color = ''} = kit;
            return color;
        },
    }
};
