<template>
    <base-modal
        class="layout-match"
        :is-close-button="true"
        :modalWidth="'850px'"
        @close="onClose"
    >
        <template v-slot:modalBody>
            <div class="layout-match__modal">
                <div class="layout-match__modal-title">
                    <base-preloader-skeleton v-if="isStepLoading"/>
                    Создать набор из файла
                </div>
                <div class="layout-match__step">
                    <base-preloader-skeleton v-if="isStepFileAttachingLoading" />
                    <match-file-attaching
                        v-if="isStepFileAttaching"
                        @attach="onFileAttach"
                    />
                    <match-field-selection
                        v-if="isStepFieldSelection"
                        :isLoading="isStepFieldSelectionLoading"
                        :combinations="stepFieldSelectionCombinations"
                        :values="stepFieldSelectionValues"
                        @onBackStep="onBackStep"
                        @onNextStep="onMatchSearch"
                    />
                    <match-side-selection
                        v-if="isStepSideSelection"
                        :isLoading="isStepSideSelectionLoading"
                        :sideSelectionData="stepSideSelectionData"
                        :sideInfo="stepSideSelectionInfo"
                        :combinationId="matchSearchCombinationData.combinationId"
                        @onDownloadSides="onDownloadSides"
                        @onBackStep="onBackStep"
                        @onNextStep="onMatchLink"
                    />
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script>
/** Лайаут для мэтчинга */
import BaseModal from "@/components/Base/BaseModal";
import MatchFileAttaching from "@/components/Match/MatchFileAttaching";
import MatchFieldSelection from "@/components/Match/MatchFieldSelection";
import MatchSideSelection from "@/components/Match/MatchSideSelection"
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton";
import ServiceBuyerdeskMatch from "../../services/ServicesBuyerdesk/ServiceBuyerdeskMatch";
export default {
    name: 'LayoutMatch',
    components: {
        BaseModal,
        MatchFileAttaching,
        MatchFieldSelection,
        MatchSideSelection,
        BasePreloaderSkeleton
    },
    /**
     * Данные компонента
     * @property {Array} steps - id шагов мэтчинка
     * @property {Number} stepCurrentIndex - индекс текущего шага
     * @property {Boolean} isStepLoading - условие загрузки данных шага
     * @property {Object} stepFieldSelectionData - данные для шага выбора комбинации и полей
     * @property {Object} stepSideSelectionData - данные для шага выбора сторон
     * @property {Object|Number} matchPrepareFileId - id загруженного файла
     * @property {Number} matchSearchStepsCount - колличество шагов для поиска сторон
     * @property {Object} matchSearchCombinationData - данные с шага выбора комбинации и полей
     */
    data: () => ({
        steps: ['fileAttaching', 'fieldSelection', 'sideSelection'],
        stepCurrentIndex: 0,
        isStepLoading: false,
        stepFieldSelectionData: {
            combinations: [],
            values: []
        },
        stepSideSelectionData: {
            approx: [],
            notFound: [],
            strict: []
        },
        matchPrepareFileId: null,
        matchSearchStepsCount: -1,
        matchSearchCombinationData: {}
    }),
    computed: {
        /**
         * Текущий шаг
         * @returns {String}
         */
        stepCurrent() {
            return this.steps[this.stepCurrentIndex];
        },
        /**
         * Является ли текущий шаг прикреплением файла
         * @returns {Boolean}
         */
        isStepFileAttaching() {
            return this.stepCurrent === 'fileAttaching';
        },
        /**
         * Является ли текущий шаг выбором комбинаций и полей
         * @returns {Boolean}
         */
        isStepFieldSelection() {
            return this.stepCurrent === 'fieldSelection';
        },
        /**
         * Является ли текущий шаг выбором сторон
         * @returns {Boolean}
         */
        isStepSideSelection() {
            return this.stepCurrent === 'sideSelection';
        },
        /**
         * Комбинации для шага выбора комбинаций и полей
         * @returns {Boolean}
         */
        stepFieldSelectionCombinations() {
            const {combinations = []} = this.stepFieldSelectionData;
            return combinations;
        },
        /**
         * Значения из файла для шага выбора комбинаций и полей
         * @returns {Boolean}
         */
        stepFieldSelectionValues() {
            const {values = []} = this.stepFieldSelectionData;
            return values;
        },
        /**
         * Условие загрузки для шага прикрепления файла
         * @returns {Boolean}
         */
        isStepFileAttachingLoading() {
            return this.isStepLoading && this.isStepFileAttaching;
        },
        /**
         * Условие загрузки для шага выбора полей и комбинаций
         * @returns {Boolean}
         */
        isStepFieldSelectionLoading() {
            return this.isStepLoading && this.isStepFieldSelection;
        },
        /**
         * Условие загрузки для шага выбора сторон
         * @returns {Boolean}
         */
        isStepSideSelectionLoading() {
            return this.isStepLoading && this.isStepSideSelection;
        },
        /**
         * Данные для поиска сторон
         * @returns {Object}
         */
        matchSearchData() {
            const matchSearchData = {
                fileId: this.matchPrepareFileId,
                stepsCount: this.matchSearchStepsCount,
                ...this.matchSearchCombinationData
            };
            return matchSearchData;
        },
        /**
         * Объект информации для шага выбора сторон
         * @returns {Object}
         */
        stepSideSelectionInfo() {
            const {combinationId = -1} = this.matchSearchCombinationData;
            const {combinations = []} = this.stepFieldSelectionData;
            const combination = combinations.find(combination => {
                const {id = -1} = combination;
                return parseInt(id) === parseInt(combinationId);
            }) ?? {};
            const {items = []} = combination;
            const stepSideSelectionInfo = items.reduce((stepSideSelectionInfo, item) => {
                const {label = {}} = item;
                const {key = '', value = ''} = label;
                const sideInfo = {
                    ...stepSideSelectionInfo,
                    [key]: String(value)
                };
                return sideInfo;
            }, {})
            return stepSideSelectionInfo;

        }
    },
    methods: {
        /**
         * Событие ошибки
         * @param {String} textError - текст ошибки
         */
        onError(textError = '') {
            alert(textError);
            this.isStepLoading = false;
        },
        /**
         * Событие закрытия
         */
        onClose() {
            ServiceBuyerdeskMatch.isRun = false;
            this.$emit('onClose');
        },
        /**
         * Событие прикрепления файла
         * @param {Object} file - файл
         */
        onFileAttach(file = null) {
            this.isStepLoading = true;
            this.uploadFile(file);
        },
        /**
         * Событие поиска конструкий
         * @param {Object} combinationData - данные выбранной комбинации и полей
         */
        onMatchSearch(combinationData = {}) {
            this.matchSearchCombinationData = combinationData;
            this.isStepLoading = true;
            this.matchSearch();
        },
        /**
         * Событие возврата к предыдущему шагу
         */
        onBackStep() {
            this.stepCurrentIndex -= 1;
        },
        /**
         * Событие загрузки сторон
         * @param {Array} sidesNums - массив сторон
         */
        onDownloadSides(sidesNums = []) {
            this.isStepLoading = true;
            this.exportFileRows(sidesNums);
        },
        /**
         * Событие создания набора
         * @param {Array} sideIds - идентификаторы сторон
         */
        onMatchLink(sideIds = []) {
            this.isStepLoading = true;
            this.matchLink(sideIds);
        },
        /**
         * Загрузка файла
         * @param {Object} file - файл
         */
        uploadFile(file = null) {
            ServiceBuyerdeskMatch.uploadFile(file, (data) => {
                const {file_id: fileId = null} = data;
                this.matchPrepare(fileId);
            }, () => {
                this.onError('Не удалось загрузить файл');
            });
        },
        /**
         * Подготовка данных к мэтчинку
         * @param {Object|Number} fileId - id файла
         */
        matchPrepare(fileId = null) {
            this.matchPrepareFileId = fileId;
            ServiceBuyerdeskMatch.matchPrepare(fileId, (data) => {
                const {combinations = [], values = [], stepsCount = -1} = data;
                this.stepFieldSelectionData = {combinations, values};
                this.matchSearchStepsCount = parseInt(stepsCount);
                this.stepCurrentIndex += 1;
                this.isStepLoading = false;
            }, () => {
                this.onError('Не удалось получить данные для параметров мэтчинга');
            });
        },
        /**
         * Поиск сторон по файла
         */
        matchSearch() {
            ServiceBuyerdeskMatch.matchSearch(this.matchSearchData, (data = {}) => {
                const {rows = {}} = data;
                const {approx = [], notFound = [], strict = []} = rows;
                this.stepSideSelectionData = {approx, notFound, strict};
                this.stepCurrentIndex += 1;
                this.isStepLoading = false;
            }, () => {
                this.onError('Произошла ошибка в поиске конструкций');
            });
        },
        /**
         * Экспорт строк
         * @param {Array} sidesNums - номера сторон
         */
        exportFileRows(sidesNums = []) {
            const exportFileRowsData = {
                fileId: this.matchPrepareFileId,
                sidesNums: sidesNums
            };
            ServiceBuyerdeskMatch.exportFileRows(exportFileRowsData, (link) => {
                window.open(link);
                this.isStepLoading = false;
            }, () => {
                this.onError('Не удалось скачать конструкции');
            });
        },
        /**
         * Завершение мэтчинга, создание набора
         * @param {Array} sideIds - id сторон
         */
        matchLink(sideIds = []) {
            const matchLinkData = {
                fileId: this.matchPrepareFileId,
                sideIds
            };
            ServiceBuyerdeskMatch.matchLink(matchLinkData, (data) => {
                this.$emit('onComplete', data);
                this.isStepLoading = false;
                this.onClose();
            }, () => {
                this.onError('Не удалось создать набор');
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .layout-match {
        &__modal {
            // width: 740px;
            max-height: 568px;
            &-title {
                display: inline-block;
                position: relative;
                font-size: 24px;
                font-weight: 400;
                line-height: 28px;
                margin-bottom: 20px;
            }
        }

        &__step {
            position: relative;
        }
    }

    .layout-match::v-deep .modal__btn-close {
        font-size: 14px;
        width: 14px;
        top: 25px;
        right: 25px;
    }

    .layout-match::v-deep .modal__container {
        padding: 40px;
        width: 740px;
    }
</style>
