<template>
	<div class="footer">
		<!-- <div v-if="Object.keys(lastUpdate).length" class="footer__left">
			Обновлено {{ lastUpdate.day }} {{ months[lastUpdate.month] }} {{ lastUpdate.year }}
		</div> -->
		<div v-if="isLegend" class="footer__right">
			<div class="color-box">
				* Все цены указаны до налога оператора
			</div>
			<div
				v-for="(item, index) of colorBoxData"
				:key="index"
				class="color-box"
			>
				<div
					class="color-box__color"
					:class="item.class"
				/>
				<div 
					:class="[
						'color-box__title', 
						item.class
					]">
					{{item.title}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {months} from "../../Scheme"
import MixinNumberModify from "@/mixins/MixinNumberModify";

export default {
	name: "Footer",
    mixins: [
        MixinNumberModify
    ],
	props: {
		lastUpdate: {
			type: Object,
			default: () => ({})
		},
        isLegend: {
            type: Boolean,
            default: true
        }
	},
	data() {
		return {
			months: months,
			colorBoxData: [
				{ class: 'free', title: "Свободно" },
				{ class: 'reserved', title: "Резерв" },
				// { class: 'booked', title: "Бронь" },
				{ class: 'busy', title: "Продано" },
			]
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$reserved-color:			#FFB300 !default;

.footer {
	color: $assistant-color;
	font-size: 10px;
	display: flex;
	// justify-content: space-between;
	justify-content: end;
	align-items: center;

    &__center {
        display: flex;

        div{
            margin-right: 10px;
        }
    }

	&__right {
		display: flex;
		align-items: center;

		.color-box:not(:last-child) {
			margin-right: 16px;
		}
	}

	.color-box {
		display: flex;
		align-items: center;

		&__color {
			height: 15px;
			width: 15px;
			border-radius: 50%;

			&.free {
				border: 3px solid $success-color;
			}
			&.reserved {
				border: 3px solid $reserved-color;
			}
			&.booked {
				border: 3px solid $danger-color;
			}
			&.busy {
				// border: 3px solid rgb(173, 176, 187);
				border: 3px solid $danger-color;
			}
		}
		&__title {
			margin-left: 6px;

			// &.busy {
			// 	text-decoration: line-through;
			// }
		}
	}
}
</style>
