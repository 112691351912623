<template>
    <Teleport v-if="!withoutBackdrop" to="body">
        <BaseModalContent
            :title="title"
            :description="description"
            :isAnimation="isAnimation"
            :withoutBackdrop="withoutBackdrop"
            :modalStyle="modalStyle"
            @close="close"
            @scrollModal="onScrollModal"
        >
            <template v-slot:modalBody>
                <slot name="modalBody"></slot>
            </template>
            <template v-slot:actionButtons>
                <slot name="actionButtons"></slot>
            </template>
        </BaseModalContent>
    </Teleport>
    <BaseModalContent
        v-else
        :title="title"
        :description="description"
        :isAnimation="isAnimation"
        :withoutBackdrop="withoutBackdrop"
        :modalStyle="modalStyle"
        @close="close"
        @scrollModal="onScrollModal"
    >
        <template v-slot:modalBody>
            <slot name="modalBody"></slot>
        </template>
        <template v-slot:actionButtons>
            <slot name="actionButtons"></slot>
        </template>
    </BaseModalContent>
</template>

<script>
import BaseModalContent from "./BaseModalContent.vue";
import Teleport from "vue2-teleport";

export default {
    name: "BaseModal",
    components: { BaseModalContent, Teleport },
    props: {
        isAnimation: Boolean,
        title: String,
        description: String,
        withoutBackdrop: Boolean,
        // modalStyle: Object,
        modalWidth: {
            type: String,
            default: ''
        },
        modalMaxHeight: {
            type: String,
            default: ''
        },
        modalStyleList: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        modalStyle() {
            let modalStyle = {};

            if (this.modalWidth)
                modalStyle.width = this.modalWidth;
            if (this.modalMaxHeight)
                modalStyle['max-height'] = this.modalMaxHeight;

            return { ...modalStyle, ...this.modalStyleList };
            // return this.modalWidth ? {width: this.modalWidth} : {}
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        onScrollModal() {
            this.$emit("scrollModal");
        },
    },
};
</script>
