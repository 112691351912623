export default {
    namespaced: true,
    state: {
        filterSelectedData: {},
        withoutPricesStepCount: 0,
        withoutPricesSideCount: 0,
        withoutPricesSideCountReal: 0,
        isFilterLoading: false,
        isFilterUpdated: false,
        cityList: [],
        filterLocations: []
    },
    getters: {
        filterSelectedData(state) {
            return state.filterSelectedData;
        },
        currentCity(state) {
            // const {city_id: cities = []} = state.filterSelectedData;
            // const cityId = cities[0];
            // const indexCity = state.cityList.findIndex(city => String(city.id) === String(cityId));
            // return state.cityList[indexCity] ?? {};

            const countriesSelected = state.filterSelectedData?.country_id ?? [];
            const regionsSelected = state.filterSelectedData?.region_id ?? [];
            const citiesSelected = state.filterSelectedData?.location_id ?? [];

            const countrySelected = state.filterLocations?.find(country => country?.id === countriesSelected[0]) ?? {};
            const regionSelected = countrySelected?.regions?.find(region => region?.id === regionsSelected[0]) ?? {};

            const citySelected = citiesSelected.length === 1 ? regionSelected?.cities.find(city => city?.id === citiesSelected[0]) : regionSelected;
            const currentCity = {
                id: citySelected?.id,
                lat: citySelected?.center?.lat,
                lng: citySelected?.center?.lng,
                zoom: citySelected?.center?.zoom,
            }

            return currentCity;
        }
    },
    mutations: {
        setFilterLocations(state, filterLocations = []) {
            state.filterLocations = filterLocations;
        },
        setCityList(state, list){
            state.cityList = list;
        },
        setFilterSelectedData(state, filterSelectedData) {
            state.filterSelectedData = filterSelectedData;
        },
        setWithoutPricesStepCount(state, withoutPricesStepCount) {
            state.withoutPricesStepCount = withoutPricesStepCount;
        },
        setWithoutPricesSideCount(state, withoutPricesSideCount) {
            state.withoutPricesSideCount = withoutPricesSideCount;
        },
        setWithoutPricesSideCountReal(state, withoutPricesSideCountReal) {
            state.withoutPricesSideCountReal = withoutPricesSideCountReal;
        },
        setIsFilterLoading(state, isFilterLoading) {
            state.isFilterLoading = isFilterLoading;
        },
        setIsFilterUpdated(state, isFilterUpdated) {
            state.isFilterUpdated = isFilterUpdated;
        },
        setCircleRadius(state, radius) {
            state.circle_radius = radius;
        },
        setCircePoints(state, circlePoints) {
            state.circe_points = circlePoints;
        },
    },
}
